import moment from 'moment';
import _ from 'lodash';
import { formatCarTrailerNumber } from '~/assets/js/utils';

function transformPayments(payments, rootGetters) {
  const isTransporter = rootGetters['main/isTransporter'];
  const currentUserCompanyId = rootGetters['main/getCurrentUser'].active_company_id;

  const flatInvoices = payments.reduce((acc, current) => {
    const withTrips = current.trips.map(trip => ({
      ...current,
      ...trip,
      id: current.id,
    }));
    return [...acc, ...withTrips];
  }, []).filter(trip => {
    if (!isTransporter) {
      return true;
    }
    return trip.transporter.id === currentUserCompanyId;
  });

  return flatInvoices.reduce((acc, curr) => {
    let transporterName = curr.transporter.name;
    const transporterRoleLabel = curr.transporter.roles[0].label;

    transporterName = `${transporterRoleLabel} ${transporterName}`;

    if (acc[transporterName]) {
      !acc[transporterName].some(({ id }) => id === curr.id)
        && acc[transporterName].push(
          {
            ...curr,
            created_at: moment(curr.created_at).format('DD.MM.YYYY'),
          },
        );
    } else {
      return {
        ...acc,
        [transporterName]: [{
          ...curr,
          created_at: moment(curr.created_at).format('DD.MM.YYYY'),
        }],
      };
    }

    return acc;
  }, []);
}

export const state = () => ({
  drivers: [],
  cars: [],
  trailers: [],
  timeToFill: 0,
  transportationModules: [],
  trips: [],
  tripLabels: {},
  isCreateTrip: false,
  history: [],
  invoices: [],
  transactionsCustomer: [],
  transactionsForwarder: [],
  transactionsTransporter: [],
  acts: [],
  query: {},
  tempMediaModelName: '',
  tempMediaModelId: null,
});

export const getters = {
  GET_DRIVERS: state => state.drivers && state.drivers.map(driver => ({
    id: driver.id,
    name: driver.profile.fullName,
    phone: driver.phone,
  })),
  GET_TRANSPORT: state => (state.cars
    && state.cars.map(cur => ({
      ...cur,
      name: `${formatCarTrailerNumber(cur.number)} ${cur.trailer_types.length > 0
        ? cur.trailer_types[0].name
        : ''}`,
    })))
    || [],
  GET_TRAILERS: state => (state.trailers
    && state.trailers.map(cur => ({
      ...cur,
      name: `${formatCarTrailerNumber(cur.number)} ${cur.trailer_types.length > 0
        ? cur.trailer_types[0].name
        : ''}`,
    })))
    || [],
  GET_TIME_TO_FILL: state => state.timeToFill,
  GET_MODULES: state => state.transportationModules,
  GET_TRIPS: state => state.trips,
  // eslint-disable-next-line no-shadow
  GET_GROUPED_TRIPS: (state, getters, rootState, rootGetters) => (Array.isArray(state.trips) ? _.flatten(state.trips) : [])
    .map(cur => {
      // eslint-disable-next-line camelcase
      const { loading_date, loading_time_from } = rootGetters['orders/orders-form/GET_FORM_DATA'];

      return {
        ...cur,
        // eslint-disable-next-line camelcase
        isOutdated: moment(`${loading_date} ${loading_time_from}`, 'YYYY-MM-DD HH-mm').isBefore(moment().add(state.timeToFill, 'hours')),
      };
    }),
  GET_IS_CREATE_TRIP: state => state.isCreateTrip,
  GET_TRIP_LABELS: state => state.tripLabels,
  GET_TRIP_HISTORY: state => state.history,
  GET_GROUPED_INVOICES: (state, getters, rootState, rootGetters) => transformPayments(state.invoices || [], rootGetters),
  GET_GROUPED_ACTS: (state, getters, rootState, rootGetters) => transformPayments(state.acts || [], rootGetters),
  GET_QUERY: state => state.query,
  GET_TRANSACTIONS_WITH_CUSTOMER: state => state.transactionsCustomer || [],
  GET_TRANSACTIONS_WITH_FORWARDER: state => state.transactionsForwarder || [],
  GET_TRANSACTIONS_WITH_TRANSPORTER: state => state.transactionsTransporter || [],
  GET_TEMP_MEDIA_MODEL_NAME: state => state.tempMediaModelName,
  GET_TEMP_MEDIA_MODEL_ID: state => state.tempMediaModelId,
};

export const actions = {
  // eslint-disable-next-line camelcase
  LOAD_TRANSPORTER_DATA({ commit, rootGetters }, { orderId, moduleId }) {
    const isTransporter = rootGetters['main/isTransporter'];
    const ORDER_SUFFIX_PATH = isTransporter
      ? `/${moduleId}`
      : '';

    return this.$axios.get(`orders/${orderId}/data-for-trip-creation${ORDER_SUFFIX_PATH}`)
      .then(({ data }) => {
        commit('setTripData', data);
        commit('setTransportationModules', data.transportation_modules);
        commit('setTripSetting', data.setting);
        commit('setIsCreateTrip', true);
      })
      .catch(error => {
        console.log(error);
      });
  },

  // eslint-disable-next-line camelcase
  LOAD_TRANSPORTER_STAFF({ commit }, transporter_id) {
    return this.$axios.get('/transporters/staff', {
      params: {
        transporter_id,
      },
    }).then(({ data }) => {
      commit('setTripData', data.data);
    });
  },

  LOAD_CARS({ commit }, params) {
    return this.$axios.get('/order/search/car?type=1', { params })
      .then(({ data }) => {
        commit('setCars', data.data);
      });
  },

  LOAD_TRAILERS({ commit }, params) {
    return this.$axios.get('/order/search/car?type=2', { params })
      .then(({ data }) => {
        commit('setTrailers', data.data);
      });
  },

  LOAD_DRIVERS({ commit }, params) {
    return this.$axios.get('/order/search/driver', { params })
      .then(({ data }) => {
        commit('setDrivers', data.data);
      });
  },

  STORE_TRIP({ commit, dispatch }, payload) {
    return this.$axios.post('/trips', { ...payload });
  },

  APPLY_FOR_BID(_, payload) {
    return this.$axios.post('/auctions/contract', { ...payload });
  },

  SEND_CHECK({ commit, dispatch }, data) {
    return this.$axios.post('/invoices/create', { ...data });
  },

  SEND_ACT({ commit, dispatch }, data) {
    return this.$axios.post('/certificates/create', { ...data });
  },

  LOAD_TRIPS({ commit }, { orderId, moduleId, with_child_orders = 0, ...params }) {
    return this.$axios.get(`/orders/${orderId}/trips`, {
      params: {
        ...params,
        orderId,
        module_id: moduleId,
        with_child_orders,
      },
    })
      .then(({ data }) => {
        commit('setTrips', data);
      }).catch(e => console.log(e));
  },

  LOAD_TRIP({ commit, dispatch }, payload) {
    return this.$axios.get(`/trips/${payload}`).then(({ data }) => {
      commit('setIsCreateTrip', false);

      return data.data;
    }).catch(e => console.log(e));
  },

  LOAD_TRIP_DATA({ commit, dispatch }, params) {
    return this.$axios.get('/trips/create', { params }).then(({ data }) => data).catch(e => console.log(e));
  },

  UPDATE_TRIP({ commit, dispatch }, payload) {
    return this.$axios.patch(`/trips/${payload.id}`, { ...payload });
  },

  CHECK_DRIVER({ commit, dispatch }, params) {
    return this.$axios.get('/trips/check-driver', { params });
  },

  CHECK_CAR_AND_TRAILER(ctx, params) {
    return this.$axios.get('trips/check-car-trailer', { params });
  },

  LOAD_TRIP_LABELS({ commit, dispatch }, payload) {
    return this.$axios.get('/trips/labels').then(({ data }) => {
      commit('setTripLabels', data);
      return data;
    }).catch(e => console.log(e));
  },

  LOAD_TRIP_HISTORY({ commit }, id) {
    return this.$axios.get(`/trips/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);

        return data.history;
      })
      .catch(e => console.log(e));
  },

  ADD_COMMENT({ commit }, { modelName, modelId, comment }) {
    return this.$axios.post('/comments', { modelName, modelId, comment });
  },

  LOAD_INVOICES({ commit, dispatch }, payload) {
    return this.$axios.get(`/invoices/index/${payload}`)
      .then(({ data }) => {
        commit('setInvoices', data.invoices);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async REMOVE_INVOICE({ commit, dispatch }, { id, orderId }) {
    await this.$axios.post('/invoices/delete', { id });
    await dispatch('LOAD_INVOICES', orderId);
  },

  LOAD_ACTS({ commit, dispatch }, payload) {
    return this.$axios.get(`/certificates/index/${payload}`).then(({ data }) => {
      commit('setActs', data.certificates);
    });
  },

  async REMOVE_ACT({ commit, dispatch }, { id, orderId }) {
    await this.$axios.post('/certificates/delete', { id });
    await dispatch('LOAD_ACTS', orderId);
  },

  SAVE_QUERY({ commit, dispatch }, payload) {
    commit('setQuery', payload);
  },

  CREATE_TRANSACTION({ commit, dispatch }, { orderId, data }) {
    return this.$axios.post(`/orders/${orderId}/transactions`, data);
  },

  UPDATE_TRANSACTION({ commit, dispatch }, { id, data }) {
    return this.$axios.patch(`/transactions/${id}`, data);
  },

  DELETE_TRANSACTION({ commit, dispatch }, transactionId) {
    return this.$axios.delete(`/transactions/${transactionId}`);
  },

  LOAD_TRANSACTIONS({ commit, dispatch }, orderId) {
    return this.$axios.get(`/orders/${orderId}/transactions`)
      .then(({ data }) => {
        commit('setTransactionsCustomer', data.transactions?.withCustomer);
        commit('setTransactionsTransporter', data.transactions?.withTransporters);
        commit('setTransactionsForwarder', data.transactions?.withForwarder);
      });
  },

  async GET_TEMP_MEDIA({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },
};

export const mutations = {
  setTripData(state, payload) {
    state.drivers = payload.drivers;
    state.cars = payload.cars;
    state.trailers = payload.trailers;
  },

  setTransportationModules(state, payload) {
    state.transportationModules = payload;
  },

  setCars(state, payload) {
    state.cars = payload;
  },

  setTrailers(state, payload) {
    state.trailers = payload;
  },

  setDrivers(state, payload) {
    state.drivers = payload;
  },

  setTripSetting(state, payload) {
    state.timeToFill = payload;
  },

  setTrips(state, payload) {
    if (Array.isArray(payload.data)) {
      state.trips = payload.data;
    } else {
      state.trips = Object.values(payload.data);
    }
  },

  addDriver(state, payload) {
    state.drivers.push(payload);
  },

  addCar(state, payload) {
    state.cars.push(payload);
  },

  setTripLabels(state, payload) {
    state.tripLabels = {
      ...payload.labels,
      ...payload.labels.trip,
      modelName: payload.modelName,
    };
  },

  setIsCreateTrip(state, payload) {
    state.isCreateTrip = payload;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  setInvoices(state, payload) {
    state.invoices = payload;
  },

  setActs(state, payload) {
    state.acts = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setTransactionsCustomer(state, payload) {
    state.transactionsCustomer = payload;
  },

  setTransactionsTransporter(state, payload) {
    state.transactionsTransporter = payload;
  },

  setTransactionsForwarder(state, payload) {
    state.transactionsForwarder = payload;
  },

  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },
};
