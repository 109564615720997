const TRIP_REJECTED_STATUS = 7;

export const state = () => ({
  realizationsList: [],
  realizationsPagination: {},
  realizationsTotalCost: {},
  isRealizationsTableLoading: false,
  query: {},
});

export const getters = {
  getRealizationsList: state => state.realizationsList,
  getRealizationsPagination: state => state.realizationsPagination,
  getRealizationsTableLoading: state => state.isRealizationsTableLoading,
  getRealizationsTotalCost: state => state.realizationsTotalCost,
  getQuery: state => state.query,
};

export const mutations = {
  setRealizationsTableLoading(state, payload) {
    state.isRealizationsTableLoading = payload;
  },

  setRealizationsList(state, payload) {
    state.realizationsList = payload.map(realization => ({
      ...realization,
      trips: realization.trips
        .filter(trip => (trip.status !== TRIP_REJECTED_STATUS && (trip.driver || trip.car))),
    }));
  },

  setRealizationsPagination(state, payload) {
    state.realizationsPagination = payload;
  },

  setRealizationsTotalCost(state, payload) {
    state.realizationsTotalCost = payload;
  },

  setRealization(state, payload) {
    state.realizationsList[payload.index] = {
      ...state.realizationsList[payload.index],
      ...payload,
    };
    state.realizationsList = [...state.realizationsList];
  },

  setQuery(state, payload) {
    state.query = payload;
  },
};

export const actions = {
  loadRealizations({ commit }, params) {
    commit('setRealizationsTableLoading', true);

    let filters = {};
    if (Number.isInteger(Number(params))) {
      filters = {
        ...state.query,
        page: params,
        clientId: params.clientId ? JSON.parse(params.clientId)?.id : undefined,
      };
    } else {
      filters = {
        ...state.query,
        ...params,
        clientId: params.clientId ? JSON.parse(params.clientId)?.id : undefined,
      };
    }

    return this.$axios.get('orders/realizations', { params: { ...filters } })
      .then(({ data }) => {
        commit('setRealizationsList', data.realizations.data);
        commit('setRealizationsPagination', data.realizations);
        commit('setRealizationsTotalCost', data.totalClientCost);
        return data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit('setRealizationsTableLoading', false);
      });
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('setQuery', payload);
  },

  updateRealization({ commit, dispatch }, payload) {
    return this.$axios.post(`/orders/${payload.orderId}/update-realization`, { ...payload })
      .then(({ data }) => {
        commit('setRealization', { ...data.order, index: payload.index });
      })
      .catch(error => {
        console.error(error);
      });
  },
};
