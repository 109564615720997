<template>
  <label
    class="t-checkbox"
    :class="{
      't-checkbox--checked': value,
      't-checkbox--disabled': disabled,
      't-checkbox--indeterminate': indeterminate,
      't-checkbox--error': hasError,
    }"
  >
    <input
      :id="id"
      v-model="value"
      type="checkbox"
      class="t-checkbox__input"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
      @change="$emit('change', $event.target.checked)"
    >
    <TIconSvg
      name="checkbox-checked"
      view-box="0 0 11 7"
      width="12"
      height="10"
      class="t-checkbox__image"
    />
  </label>
</template>

<script>
export default {
  name: 'TCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: '',
    },
  },

  computed: {
    indeterminate() {
      return this.value === null;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.t-checkbox {
  $self: &;
  $size: 16px;

  @include centerFlexXY;
  @include squareBox($size);
  flex: none;
  border: 1px solid $light-grey;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    border-color: darken($light-grey, 10%);
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      border-color: $light-grey;
    }
  }

  &--indeterminate::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background-color: $brick-red;
    border-radius: 1px;
  }

  &__input {
    display: none;
  }

  &__image {
    opacity: 0;
    transition: opacity .2s ease-in-out;

    #{$self}--checked & {
      opacity: 1;
    }
  }
}
</style>
