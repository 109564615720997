<template>
  <div>
    <transition name="header-menu">
      <div
        class="pb-2 sidepanel-header-menu"
        :class="{'sidepanel-header-menu--empty': companiesList.length < 2}"
      >
        <ul
          v-if="!isAdmin && companiesList.length > 1"
          class="sidepanel-header-menu__companies"
        >
          <li
            v-for="(company) of companiesList"
            :key="company.id"
            class="mb-2 sidepanel-header-menu__company"
            @click="changeCompany(company)"
          >
            <span class="sidepanel-header-menu__company-text">
              {{company.roles[0].label}} {{company.name}}
            </span>
            <TRadio
              :value="activeCompanyId"
              :property-value="company.id"
              @click.native.prevent
            />
          </li>
        </ul>
        <div class="sidepanel-header-menu__separator" />
        <TFlex column>
          <TButton
            color="white"
            transparent
            :icon="`locales/${$i18n.locale}`"
            icon-width="19"
            icon-height="13"
            icon-fill="none"
            icon-stroke="none"
            icon-view-box="0 0 19 13"
            class="sidepanel-header-menu__button"
            @click.stop="openLocalePopup"
          >
            {{currentLocaleName}}
          </TButton>
          <TButton
            color="white"
            transparent
            icon="exit"
            icon-width="19"
            icon-height="19"
            icon-fill="none"
            icon-stroke="none"
            icon-view-box="0 0 17 15"
            class="sidepanel-header-menu__button"
            :disabled="isLogoutBtnLoading"
            @click="logout"
          >
            {{$t('sidepanel.Logout')}}
          </TButton>
        </TFlex>
      </div>
    </transition>
    <SidepanelMenuLocalePopup
      v-if="isLocalePopupShown"
      @accept="switchLocale"
      @cancel="closeLocalePopup"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// mixins
import accessMixin from '~/assets/js/mixins/accessMixin';

// Components
import SidepanelMenuLocalePopup from './SidepanelMenu/components/SidepanelMenuLocalePopup';

// Constants
import { I18N_COOKIE } from '~/assets/js/constants';

const RELOAD_TRIGGER = 'reloadTrigger';

export default {
  name: 'SidepanelHeaderMenu',

  components: {
    SidepanelMenuLocalePopup,
  },

  mixins: [accessMixin],

  props: {
    position: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isLocalePopupShown: false,
  }),

  computed: {
    ...mapGetters({
      isLogoutBtnLoading: 'auth/login/GET_LOGOUT_CONTROL_LOADING',
      companiesList: 'main/getUserCompaniesList',
      activeCompanyId: 'main/getUserActiveCompanyId',
    }),

    currentLocaleName() {
      return this.$i18n.locales.find(locale => locale.code === this.$i18n.locale).name;
    },
  },

  methods: {
    ...mapActions({
      logOut: 'auth/login/LOG_OUT',
      removeAuthCookie: 'general/REMOVE_AUTH_COOKIE',
      removeAuthHeader: 'general/REMOVE_AUTH_HEADER',
      updateCurrentCompany: 'main/updateCurrentCompany',
      saveLocale: 'main/saveLocale',
    }),

    changeCompany(newCompany) {
      this.updateCurrentCompany(newCompany.id);
    },

    openLocalePopup() {
      this.isLocalePopupShown = true;
    },

    switchLocale(newLocaleCode) {
      this.$cookies.set(I18N_COOKIE, newLocaleCode);
      this.$axios.setHeader('Accept-Language', newLocaleCode);
      this.saveLocale(newLocaleCode);
      this.closeLocalePopup();
      this.$router.go();
    },

    closeLocalePopup() {
      this.isLocalePopupShown = false;
    },

    async logout() {
      try {
        await this.logOut();
        this.removeAuthCookie();
        this.removeAuthHeader();

        this.$router.push('/login');
        localStorage.setItem(RELOAD_TRIGGER, new Date().valueOf());
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.sidepanel-header-menu {
  $self: &;

  position: absolute;
  top: 38px;
  left: 9px;
  z-index: 1;
  padding: 0 17px;
  width: 238px;
  background-color: #39464d;
  box-shadow: 4px 6px 10px rgba(25, 38, 45, 0.5);

  &--empty {
    top: 62px;
  }

  &__separator {
    border-top: 1px solid rgba(212, 217, 220, 0.5);
    width: 100%;
  }

  &__companies {
    margin: 0;
    padding-left: 0;
  }

  &__company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-brown-grey;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &-text {
      max-width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .t-radio.t-radio--checked::before {
      background-color: $golden-yellow;
    }

    .t-radio__text-container {
      display: none;
    }
  }

  &__button {
    margin-top: 6px;
    width: 100%;
    border-radius: 0;
  }

  &__error {
    white-space: normal;
    background-color: rgba(#fff, 0.4);
  }

  .t-button {
    justify-content: flex-start;
    font-weight: 400;
    transition: opacity 0.3s;

    &__icon {
      margin-right: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
}

.header-menu-enter-active,
.header-menu-leave-active {
  transition: opacity 0.3s;
}

.header-menu-enter,
.header-menu-leave-to {
  opacity: 0;
}
</style>
