<template>
  <div class="t-table">
    <table class="t-table__table">
      <thead class="t-table__thead">
        <tr class="t-table__th-tr">
          <slot
            name="header"
            :headers="headers"
          />
        </tr>
      </thead>
      <tbody
        v-if="!isLoading"
        class="t-table__tbody"
      >
        <template v-if="body && body.length > 0">
          <template v-for="(item, index) in tableData">
            <tr
                :key="item.id || item.vueKey"
                class="t-table__tb-tr"
                :class="{ 't-table__tb-tr--flex':  bodyRowFlex}"
                @click="$emit('click', index)"
            >
              <slot
                  name="body"
                  :item="item"
                  :index="index"
              />
            </tr>
            <slot
                name="additional-body"
                :item="item"
                :index="index"
                :headers="headers"
            />
          </template>
        </template>
      </tbody>
      <tfoot class="t-table__tfoot">
        <slot name="footer" />
      </tfoot>
    </table>
    <div
      v-if="isLoading"
      class="t-table__preloader-wrapper"
    >
      <TPreloader
        class="t-table__preloader"
        :color="'#5E6979'"
        size="40"
      />
    </div>
    <div
      v-if="isShownEmpty && (!body || (body && body.length === 0)) && !isLoading"
      class="t-table__empty"
    >
      <slot name="empty" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TTable',

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    body: {
      type: Array,
      default: () => [],
    },

    isShownEmpty: {
      type: Boolean,
      default: false,
    },

    isScrollable: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    bodyRowFlex: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tableData() {
      return this.body.map((item, index) => ({
        ...this.headers[index],
        headers: this.headers,
        item,
      }));
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-table {
  &__tb-tr {
    cursor: pointer;

    &--flex {
      display: flex;
    }
  }

  &__empty {
    position: relative;
  }

  &__preloader {
    left: 50%;
    transform: translateX(-50%);

    &-wrapper {
      border-top: 1px solid $light-grey;
      width: 100%;
      padding: 16px;
    }
  }
}
</style>
