import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"1.0.0","apiKey":"97356e65d1ce1fcb93c22c11444077e0","releaseStage":"production","enabledReleaseStages":["staging","production"],"publishRelease":true}
options.plugins = [new BugsnagPluginVue(Vue)]
const bugsnagClient = Bugsnag.start(options)

export default function ({ app }, inject) {
  inject('bugsnag', bugsnagClient)
}
