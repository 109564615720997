export const state = () => ({
  tariffScalesList: [],
  tariffScalesPagination: {},
  isTariffScalesTableLoading: false,
  query: {},
});

export const getters = {
  getTariffScalesList: state => state.tariffScalesList,
  getTariffScalesPagination: state => state.tariffScalesPagination,
  getTariffScalesTableLoading: state => state.isTariffScalesTableLoading,
  getTariffScalesTotalCost: state => state.tariffScalesTotalCost,
  getQuery: state => state.query,
};

export const mutations = {
  setTariffScalesTableLoading(state, payload) {
    state.isTariffScalesTableLoading = payload;
  },

  setTariffScalesList(state, payload) {
    state.tariffScalesList = payload;
  },

  setTariffScalesPagination(state, payload) {
    state.tariffScalesPagination = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },
};

export const actions = {
  loadTariffScales({ commit }, params) {
    commit('setTariffScalesTableLoading', true);

    let filters = {};
    if (Number.isInteger(Number(params))) {
      filters = {
        ...state.query,
        page: params,
      };
    } else {
      filters = {
        ...state.query,
        ...params,
      };
    }

    return this.$axios.get('tariffs/list', { params: { ...filters } })
      .then(({ data }) => {
        commit('setTariffScalesList', data.data.data);
        commit('setTariffScalesPagination', data.data);
        return data;
      }).finally(() => {
        commit('setTariffScalesTableLoading', false);
      });
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('setQuery', payload);
  },
};
