<template>
  <TPopup
    class="sidepanel-menu-locale-popup"
    width="340px"
    overlay-close
    @close="cancel"
  >
    <template slot="header">
      <THeading class="sidepanel-menu-locale-popup__heading">
        {{$t('sidepanel.Select Language')}}
      </THeading>
    </template>
    <ul class="pl-0 sidepanel-menu-locale-popup__list">
      <li
        v-for="locale of $i18n.locales"
        :key="locale.code"
        class="sidepanel-menu-locale-popup__item"
      >
        <div
          class="sidepanel-menu-locale-popup__row"
          :class="{
            'sidepanel-menu-locale-popup__row--active': locale.code === selectedLocale
          }"
          @click="selectLocale(locale.code)"
        >
          <TIconSvg
            :name="`locales/${locale.code}`"
            class="mr-2"
            width="19"
            height="13"
            view-box="0 0 19 13"
          />
          <span class="sidepanel-menu-locale-popup__label">{{locale.name}}</span>
          <TRadio
            class="sidepanel-menu-locale-popup__radio"
            :value="selectedLocale"
            :property-value="locale.code"
          />
        </div>
      </li>
    </ul>
    <template #bottom>
      <div>
        <TButton
          class="mr-2"
          @click="accept"
        >
          {{$t('Apply')}}
        </TButton>
        <TButton
          secondary-action
          @click="cancel"
        >
          {{$t('Cancel')}}
        </TButton>
      </div>
    </template>
  </TPopup>
</template>

<script>
export default {
  name: 'SidepanelMenuLocalePopup',

  data: () => ({
    selectedLocale: '',
  }),

  created() {
    this.selectedLocale = this.$i18n.locale;
  },

  methods: {
    accept() {
      this.$emit('accept', this.selectedLocale);
    },

    cancel() {
      this.$emit('cancel');
    },

    selectLocale(newLocale) {
      this.selectedLocale = newLocale;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.sidepanel-menu-locale-popup {
  $root: &;
  cursor: default;

  color: $almost-black;

  &__row {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 3px;
    cursor: pointer;

    .t-radio__text-container {
      display: none;
    }

    &--active {
      background: $very-light-grey;

      #{$root}__label {
        color: $almost-black
      }
    }
  }

  &__label {
    color: $steel-grey;
  }

  &__radio {
    margin-left: auto !important;
  }
}
</style>
