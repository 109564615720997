<template>
  <div
    ref="counter"
    class="t-addition-counter"
    @mouseenter="handleMouseOnCounter"
    @mouseleave="handleMouseLeaveCounter"
  >
    <span class="t-addition-counter__count">+{{count}}</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'TAdditionCounter',

  props: {
    count: {
      type: Number,
      required: true,
    },
  },

  date: () => ({
    isMouseOnCounter: false,
    isMouseOnDropdown: false,
    isDropdownShown: false,
  }),

  computed: {
    counterCoords() {
      const { counter } = this.$refs;

      const box = counter.getBoundingClientRect();

      return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset,
      };
    },
  },

  beforeDestroy() {
    document.removeEventListener('mouseenter', this.handleMouseOnDropdown);
    document.removeEventListener('mouseleave', this.handleMouseLeaveDropdown);
  },

  methods: {
    handleMouseOnCounter() {
      this.isMouseOnCounter = true;

      if (!this.isDropdownShown) {
        this.isDropdownShown = true;

        const dropdown = this.createDropdown();
        this.insertDropdown(dropdown, 'body');
      }
    },

    handleMouseLeaveCounter() {
      this.isMouseOnCounter = false;
      this.checkAndDeleteDropdown();
    },

    handleMouseOnDropdown() {
      this.isMouseOnDropdown = true;
    },

    handleMouseLeaveDropdown() {
      this.isMouseOnDropdown = false;
      this.checkAndDeleteDropdown();
    },

    createDropdown() {
      const VERTICAL_OFFSET = 13;
      const HORIZONTAL_OFFSET = 2;

      const { top, left } = this.counterCoords;

      const CounterDropdown = Vue.extend({
        render: h => (
          <div
            class="t-addition-counter__dropdown"
            style={{
              top: `${top + VERTICAL_OFFSET}px`,
              left: `${left + HORIZONTAL_OFFSET}px`,
            }}
          >
            {this.$slots.default}
          </div>
        ),
      });

      const element = new CounterDropdown().$mount().$el;

      element.addEventListener('mouseenter', this.handleMouseOnDropdown);
      element.addEventListener('mouseleave', this.handleMouseLeaveDropdown);

      return element;
    },

    insertDropdown(element, query) {
      document.querySelector(query).appendChild(element);
    },

    checkAndDeleteDropdown() {
      const REMOVE_TIMEOUT = 100;

      setTimeout(() => {
        if (this.isMouseOnCounter || this.isMouseOnDropdown) {
          return;
        }

        this.isDropdownShown = false;

        const dropdown = document.querySelector('.t-addition-counter__dropdown');

        if (dropdown) {
          dropdown.remove();
        }
      }, REMOVE_TIMEOUT);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-addition-counter {
  width: 21px;
  height: 17px;
  cursor: pointer;
  padding: 5px 3px;
  align-items: center;
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  border: 1px solid $light-grey;
  transition: 0.15s ease-in border-color;

  &:hover {
    border: 1px solid $steel-grey;
  }

  &__count {
    font-size: 10px;
    text-align: center;
  }

  &__dropdown {
    position: absolute;
    padding: 10px 15px;
    border-radius: 2px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid $light-grey;
    box-shadow: 4px 4px 10px $almost-black-shadow;
  }
}
</style>
