<template>
  <transition name="toast">
    <div>
      <div
        :style="[
          { width },
          { height },
        ]"
        class="c-toast pl-5 pr-4"
      >
        <div class="c-toast__container">
          <div
            v-if="hasHeader"
            class="c-toast__header"
          >
            <slot name="header" />
          </div>
          <div
            v-if="hasBody"
            class="c-toast__body"
          >
            <slot name="body" />
          </div>
        </div>
        <button
          type="button"
          :class="{'has-header': hasHeader}"
          class="c-toast__close-button"
          @click="closeToast"
        >
          <TIconSvg
            name="close"
            fill="#979797"
            stroke="none"
            height="14"
            width="14"
            view-box="0 0 11 11"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TToast',

  props: {
    withTimer: {
      type: Boolean,
      default: true,
    },

    timerSeconds: {
      type: Number,
      default: 5,
    },

    width: {
      type: String,
      default: '',
    },

    height: {
      type: String,
      default: '',
    },

    isDesktopAndUp: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    countdownTimeout: null,
  }),

  computed: {
    hasHeader() {
      return this.$slots.header !== undefined;
    },

    hasBody() {
      return this.$slots.body !== undefined;
    },
  },

  created() {
    if (this.withTimer) {
      this.setCountdownTimer();
    }
  },

  mounted() {
    setTimeout(() => {
      document.addEventListener('click', this.clickListener);
    }, 0);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickListener);
  },

  methods: {
    setCountdownTimer() {
      this.countdownTimeout = setTimeout(() => {
        this.$emit('close');
      }, this.timerSeconds * 1000);
    },

    closeToast() {
      clearTimeout(this.countdownTimeout);

      this.$emit('close');
    },
    clickListener() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.c-toast {
  position: fixed;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  z-index: 999;
  min-height: 64px;
  border-radius: 1px;
  background-color: #fff;
  transition: opacity 0.3s ease;
  border: 1px solid $light-grey;
  display: flex;
  align-items: center;
  box-shadow: $shadow-black;

  &__close-button {
    font-size: 0;
    cursor: pointer;
  }

  &__header {
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
  }

  &__body {
    padding-right: 40px;
  }
}

.toast-enter {
  opacity: 0;
}

.toast-leave-active {
  opacity: 0;
}
</style>
