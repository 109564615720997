export const state = () => ({
  deliveryServices: [],
  tickers: [],
  letters: [],
  letterInfo: null,
  isTableLoading: false,
  letterStatuses: [],
  pagination: {},
  statistics: {},
  documentStatuses: null,
  documentTypes: [],
  backendQuery: null,
});

export const getters = {
  deliveryServices: state => state.deliveryServices,
  tickers: state => state.tickers,
  letters: state => state.letters,
  isTableLoading: state => state.isTableLoading,
  getLetterStatuses: state => Object.values(state.letterStatuses),
  getPagination: state => state.pagination,
  letterInfo: state => ({
    ...state.letterInfo,
    status: state.letterStatuses[state.letterInfo.status],
  }),
  getLetterStatistics: state => state.statistics,
  getDocumentStatuses: state => Object.values(state.documentStatuses),
  getDocumentTypes: state => state.documentTypes,
  getBackendQuery: state => state.backendQuery,
};

export const mutations = {
  setDeliveryServices(state, payload) {
    state.deliveryServices = payload;
  },

  setTickers(state, payload) {
    state.tickers = payload;
  },

  setCorrespondenceStatuses(state, payload) {
    state.letterStatuses = payload.statuses;
    state.documentStatuses = payload.documentStatuses;
    state.documentTypes = payload.documentTypes;
  },

  setIsTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setLetters(state, payload) {
    state.letters = payload;
  },

  setLetterInfo(state, payload) {
    state.letterInfo = payload;
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },

  setStatistics(state, payload) {
    state.statistics = payload;
  },

  setBackendQuery(state, payload) {
    state.backendQuery = payload;
  },

  setDocumentTypes(state, payload) {
    state.documentTypes = payload;
  },

  setDocumentStatuses(state, payload) {
    state.documentStatuses = payload;
  },
};

export const actions = {
  async getCreatingData({ commit }) {
    const { data } = await this.$axios.get('/writings/create');

    commit('setDeliveryServices', data.deliveryServices);
    commit('setTickers', data.tickers);
  },

  create({ dispatch }, formData) {
    return this.$axios.post('/writings', formData);
  },

  delete(_, payload) {
    return this.$axios.delete(`/writings/${payload}`);
  },

  async loadLetterInfo({ commit }, id) {
    try {
      const { data: { writing, documentTypes, documentStatuses } } = await this.$axios.get(`/writings/${id}/edit`);
      commit('setLetterInfo', writing);
      commit('setDocumentTypes', documentTypes);
      commit('setDocumentStatuses', documentStatuses);
    } catch (error) {
      console.log(error);
    }
  },

  async loadLetters({ commit }, payload) {
    const params = { ...payload };
    if (payload?.statuses && !Array.isArray(payload?.statuses)) {
      params.statuses = [payload.statuses];
    }

    if (payload?.document_statuses && !Array.isArray(payload?.document_statuses)) {
      params.document_statuses = [payload.document_statuses];
    }

    if (payload?.document_types && !Array.isArray(payload?.document_types)) {
      params.document_types = [payload.document_types];
    }

    if (payload?.do_specialist && !Array.isArray(payload?.do_specialist)) {
      params.do_specialist = [payload.do_specialist];
    }

    if (payload?.senders && !Array.isArray(payload?.senders)) {
      params.senders = [payload.senders];
    }

    try {
      commit('setIsTableLoading', true);
      const {
        data: {
          data: letters,
          meta: pagination,
          quantities,
          do_specialist,
          senders,
        },
      } = await this.$axios.get('/writings', { params });

      commit('setLetters', letters);
      commit('setPagination', pagination);
      commit('setStatistics', quantities);
      commit('setBackendQuery', { do_specialist, senders });
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async loadCorrespondenceStatuses({ commit }) {
    try {
      const { data } = await this.$axios.get('/writings/statuses');
      commit('setCorrespondenceStatuses', data);
    } catch (error) {
      console.log(error);
    }
  },

  async processLetters({ commit }, payload) {
    commit('setIsTableLoading', true);

    try {
      await this.$axios.patch('/writings/set-processed', payload);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  saveDocuments(_, { id, data }) {
    return this.$axios.post(`/writings/${id}/documents`, { documents: [...data] });
  },

  async updateWriting(_, { id, data }) {
    try {
      await this.$axios.put(`/writings/${id}`, data);
    } catch (error) {
      console.log(error);
    }
  },
};
