<template>
  <span
    class="t-pseudo-link"
    :class="{
      't-pseudo-link--disabled': isDisabled,
      't-pseudo-link--underline': isUnderline,
    }"
    @click="$emit('click', $event)"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'TPseudoLink',

  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },

    isUnderline: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-pseudo-link {
  margin: 0;
  padding: 0;
  position: relative;
  color: $light-blue;
  outline: none;
  cursor: pointer;
  transition: color 0.3s, opacity 0.3s;

  &:hover:not(.t-pseudo-link--underline) {
    color: darken($light-blue, 15%);
  }

  &:active:not(.t-pseudo-link--disabled) {
    color: rgba($light-blue, 0.7);
  }

  &--disabled {
    cursor: default;
    color: $steel-grey;
  }

  &--underline {
    border-bottom: 1px dashed $light-blue;
    transition: border-bottom-color 0.2s;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
</style>
