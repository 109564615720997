export const state = () => ({
  comments: [],
});

export const getters = {
  getComments: state => state.comments,
};

export const mutations = {
  setComments(state, payload) {
    state.comments = payload;
  },
};

export const actions = {
  async sendComment({ commit }, params) {
    await this.$axios.post(params.additionRoute || '/comments', params)
      .catch(err => {
        console.log(err);
      });
  },

  async loadComments({ commit }, params) {
    try {
      if (params.additionRoute) {
        const { data } = await this.$axios.get(params.additionRoute, { params });
        commit('setComments', data.comments);

        return data.comments;
      }
      const { data } = await this.$axios.get('/comments', { params });
      commit('setComments', data.comments);

      return data.comments;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};
