<template>
  <div
    class="sidepanel"
    :class="{ 'sidepanel--shrunk': isShrunk }"
  >
    <div class="sidepanel__wrapper">
      <div
        class="sidepanel__toggle"
        @click="shrunkMenu"
      >
        <TIconSvg
          class="sidepanel__toggle-icon"
          :class="{
            'sidepanel__toggle-icon--shrunk': isShrunk,
          }"
          name="menu/arrow"
          width="7"
          height="12"
          view-box="0 0 7 12"
        />
      </div>
      <div
        v-bar
        class="sidepanel__scroll-wrapper"
        :class="{
          'sidepanel__scroll-wrapper--shrunk': isShrunk,
        }"
      >
        <div class="sidepanel__inner">
          <SidepanelHeader
            :name="userName"
            :short-name="shortUserName"
            :has-double-surname="hasDoubleSurname"
            :position="userPosition"
            :company="userCompanyName"
            :is-shrunk="isShrunk"
          />
          <SidepanelMenu
            class="sidepanel__menu"
            :items="menu"
            :is-shrunk="isShrunk"
            @unshrunk-menu="unshrunkMenu"
          />
        </div>
      </div>
      <div
        v-show="!isShrunk"
        class="sidepanel__help"
      >
        <div>
          {{$t('auth.If you have any questions, please contact support')}}
          <br>
          <a :href="`mailto:${$options.emailLink}`">{{$options.emailLink}}</a>
          </br>
          <a :href="`tel:${$options.phoneNumber}`">{{$options.phoneNumber}}</a>
        </div>
        <!-- Временно скрыл, пока не сделают телеграм бота -->
        <div
          v-if="false"
          class="mt-4"
        >
          {{$t('sidepanel.To connect notifications in Telegram follow the link')}}
          <a
            class="sidepanel__help--underline"
            href="https://t.me/oiweurt94rc932i90583_bot"
          >
            {{$t('sidepanel.link')}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// utils
import { formatPhone } from '~/assets/js/utils';
// mixins
import accessMixin from '~/assets/js/mixins/accessMixin';
// Components
import SidepanelHeader from './components/SidepanelHeader';
import SidepanelMenu from './components/SidepanelMenu';
// Constants
import { ALLTRUCKS_SUPPORT_EMAIL, ALLTRUCKS_SUPPORT_PHONE } from '~/assets/js/constants';

export default {
  name: 'Sidepanel',

  components: {
    SidepanelHeader,
    SidepanelMenu,
  },

  emailLink: ALLTRUCKS_SUPPORT_EMAIL,
  phoneNumber: ALLTRUCKS_SUPPORT_PHONE,

  mixins: [accessMixin],

  props: {
    isShownSidebar: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      userCompaniesList: 'main/getUserCompaniesList',
      userCompany: 'main/getUserCompany',
      isUserCompanyRequiringAccreditation: 'main/isUserCompanyRequiringAccreditation',
    }),

    menu() {
      return [
        {
          text: this.$t('sidepanel.Orders'),
          icon: 'menu/orders',
          link: '/orders',
          iconViewBox: '0 0 23 20',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewOrders || this.isUserCompanyRequiringAccreditation,
        },
        // trips here
        {
          text: this.$t('sidepanel.Bidding'),
          icon: 'menu/bidding',
          link: '/bidding',
          iconViewBox: '0 0 23 23',
          iconWidth: 23,
          iconHeight: 23,
          isShow: this.isViewBidding,
        },
        {
          text: !this.isTransporter ? this.$t('sidepanel.Reports') : this.$t('sidepanel.Trips'),
          icon: 'menu/trips',
          link: !this.isTransporter ? '#' : '/trips',
          iconViewBox: '0 0 22 21',
          iconWidth: 25,
          iconHeight: 24,
          iconStroke: 'currentColor',
          isShow: this.isViewStatistics
                  || this.isViewRealizations,
          children: [
            {
              text: this.$t('sidepanel.Statistics'),
              link: '/trips',
              isShow: this.isViewStatistics,
            },
            {
              text: this.$t('sidepanel.Realizations'),
              link: '/reports/realizations',
              isShow: this.isViewRealizations,
            },
          ].filter(child => child.isShow),
        },
        // {
        //   text: 'Статистика',
        //   icon: 'menu/statistic',
        //   link: '#',
        //   iconViewBox: '0 0 22 21',
        //   iconWidth: 25,
        //   iconHeight: 24,
        //   iconStroke: 'currentColor',
        //   isShow: this.isAdmin || this.isClientOrForwarder,
        //   children: [
        //     {
        //       text: 'Реализации',
        //       icon: 'menu/realizations',
        //       link: '/statistic/realizations',
        //       iconViewBox: '0 0 22 21',
        //       iconWidth: 25,
        //       iconHeight: 24,
        //       iconStroke: 'currentColor',
        //       isShow: this.isAdmin || this.isClientOrForwarder,
        //     },
        //   ],
        // },
        {
          text: this.$t('sidepanel.Contractors'),
          icon: 'menu/companies',
          link: '/companies',
          iconViewBox: '0 0 25 17',
          iconWidth: 25,
          iconHeight: 17,
          isShow: this.isViewCustomers || this.isViewTransporter || this.isViewBranches,
        },
        {
          text: this.$t('sidepanel.Directories'),
          icon: 'menu/reference',
          iconViewBox: '0 0 18 18',
          iconWidth: 23,
          iconHeight: 19,
          isShow: this.isViewTariffScales || this.isViewDirectoriesTransporters || this.isViewTransportersGroups,
          children: [
            {
              text: this.$t('sidepanel.Tariff grid'),
              link: '/references',
              isShow: this.isViewTariffScales,
            },
            {
              text: this.$t('sidepanel.Transporters'),
              link: '/transporters',
              isShow: this.isViewDirectoriesTransporters,
            },
            {
              text: this.$t('transportersGroups.Transporters groups'),
              link: '/transporters-groups',
              isShow: this.isViewTransportersGroups,
            },
          ].filter(child => child.isShow),
        },
        {
          text: this.$t('sidepanel.Transport'),
          icon: 'menu/transport',
          link: '/transport',
          iconViewBox: '0 0 27 24',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewCars,
        },
        {
          text: this.$t('sidepanel.Drivers'),
          icon: 'menu/drivers',
          link: '/drivers',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewDrivers,
        },
        {
          text: this.$t('sidepanel.Staff'),
          icon: 'menu/users',
          link: '/users',
          iconViewBox: '0 0 25 17',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewUser,
        },
        {
          text: this.$t('sidepanel.Company Profile'),
          icon: 'menu/profile',
          link: '/profile',
          iconStroke: 'currentColor',
          iconViewBox: '-4 -1 19 24',
          iconWidth: 22,
          iconHeight: 27,
          isShow: this.isViewProfile,
        },
        {
          text: this.$t('sidepanel.Settings'),
          icon: 'menu/settings',
          link: '/settings',
          iconStroke: 'currentColor',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewSettings,
        },
        {
          text: this.$t('sidepanel.Instructions'),
          icon: 'menu/instructions',
          link: '/instructions',
          iconStroke: 'currentColor',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: true,
        },
        {
          text: this.$t('sidepanel.Correspondence'),
          icon: 'menu/correspondence',
          link: '/correspondence',
          iconViewBox: '0 0 23 20',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewCorrespondence,
        },
      ].filter(v => v.isShow);
    },

    userName() {
      return this.currentUser
        && this.currentUser.profile
        && (this.currentUser.profile.fullName.trim()
          ? this.currentUser.profile.fullName
          : this.formatPhone(this.currentUser.phone));
    },

    shortUserName() {
      return this.currentUser
        && this.currentUser.profile
        && this.currentUser.profile.shortName;
    },

    hasDoubleSurname() {
      return this.currentUser
        && this.currentUser.profile
        && this.currentUser.profile.surname
        && this.currentUser.profile.surname.includes('-');
    },

    userPosition() {
      if (this.isAdmin) {
        return this.$t('Administrator');
      }
      return this.currentUser?.active_company?.roles?.[0].label || '';
    },

    userCompanyName() {
      if (this.isAdmin) {
        return '';
      }

      return this.userCompany?.name;
    },

    isShrunk() {
      return this.$store.getters['general/IS_MENU_SHRUNK'];
    },
  },

  methods: {
    formatPhone,

    shrunkMenu() {
      this.$cookies.set('isMenuOpened', !this.isShrunk);
      this.$store.dispatch('general/TOGGLE_MENU_SHRUNK', !this.isShrunk);
    },

    unshrunkMenu() {
      this.$cookies.set('isMenuOpened', false);
      this.$store.dispatch('general/TOGGLE_MENU_SHRUNK', false);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.sidepanel {
  $self: &;

  color: #FFF;
  height: 100vh;
  width: $full-sidepanel-width;
  min-width: $full-sidepanel-width;
  position: relative;
  transition: width 0.2s;

  &__help {
    position: absolute;
    bottom: 26px;
    left: 28px;
    padding-right: 35px;

    a {
      color: $light-blue;
      &:hover {
        color: darken($light-blue, 5%);
      }
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    z-index: 232;
    top: 52px;
    background-color: $golden-yellow;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    #{$self}__toggle {
      opacity: 1;
    }
  }

  &--shrunk {
    width: $shrunk-sidepanel-width;
    min-width: $shrunk-sidepanel-width;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 115;
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
  }

  &__scroll-wrapper {
    height: 100vh;
    width: $full-sidepanel-width;
    padding: 0 0 200px 0;
    overflow-y: auto;
    transition: width 0.2s;
    background-color: $almost-black;

    &--shrunk {
      width: $shrunk-sidepanel-width;
    }

    &--hidden {
      width: 0;
    }
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
    position: relative;
  }

  &__toggle-icon {
    transform: rotate(180deg);
    margin-right: 1px;

    &--shrunk {
      transition: width 0.2s;
      transform: rotate(0deg);
      margin-right: 0;
    }
  }
}
</style>
