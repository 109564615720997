export const state = () => ({});

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { app, route, redirect }) {
    const token = app.$cookies.get('token');
    const noAuthNeeded = route.name === 'login'
      || route.name === 'register'
      || route.name === 'password-recovery';

    const publicPages = route.name === 'public-orders'
      || route.name === 'public-orders-id'
      || route.name === 'unsubscribe';

    const nds = app.$cookies.get('nds');

    if (app.$cookies.get('nds') === undefined) {
      app.$cookies.set('nds', true);
    }

    const isMenuOpened = app.$cookies.get('isMenuOpened');

    if (isMenuOpened !== undefined) {
      commit('general/setMenuShrunk', isMenuOpened);
    }

    commit('general/setNdsValue', nds);

    if (token) {
      commit('general/setToken', token);
      await dispatch('general/SET_AUTH_HEADER', token);
      await dispatch('general/SET_AUTH_COOKIE', token);

      await dispatch('main/getInfo');

      if (publicPages) {
        return;
      }

      if (noAuthNeeded) {
        redirect('/');
      }
    } else if (!noAuthNeeded && !publicPages) {
      redirect('/login');
    }
  },
};
