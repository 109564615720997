export const state = () => ({
  companiesData: {},
  filtersLabels: {},
  query: {},
  customerDocModelName: '',
  companyModelName: '',
  customerDocCollectionName: '',
  transporterClientModelName: '',
  documentMainModelName: '',
  tempMediaModelName: '',
  tempMediaModelId: null,
  mainContractModelName: '',
  fileLoaderProps: {},
  companyFullName: '',
  transporterCustomersData: {},
  regions: [],
  trailer_types: [],
  orderDocumentTypes: [],
  isClientPage: false,
  companyCurrentTab: ['transporter'],
  legalCheckComments: [],
  isTableLoading: false,
  isCustomersTableLoading: false,
  isFormCanceling: false,
  selectedCompanies: [],
});

export const getters = {
  list: state => state.companiesData.data || [],
  pagination: state => state.companiesData.meta,
  filtersLabels: state => state.filtersLabels.labels.company,
  legalCheckList: state => {
    if (state.filtersLabels.labels.company) {
      return Object.values(state.filtersLabels.labels.company.collections.mediaCompany) || [];
    }

    return [];
  },
  query: state => state.query,
  customerDocModelName: state => state.customerDocModelName,
  companyModelName: state => state.companyModelName,
  transporterClientModelName: state => state.transporterClientModelName,
  documentMainModelName: state => state.documentMainModelName,
  customerDocCollectionName: state => state.customerDocCollectionName,
  getTempMediaModelName: state => state.tempMediaModelName,
  getTempMediaModelId: state => state.tempMediaModelId,
  mainContractModelName: state => state.mainContractModelName,
  fileLoaderProps: state => state.fileLoaderProps,
  fileStatusLabels: state => state.filtersLabels.labels.statuses.mediaStatuses,
  companyStatuses: state => state.filtersLabels.labels.statuses.companyStatuses,
  companyFullName: state => state.companyFullName,
  transporterCustomersData: state => state.transporterCustomersData,
  getLegalCheckComments: state => state.legalCheckComments,
  isClientPage: state => state.isClientPage,
  companyCurrentTab: state => state.companyCurrentTab,
  isTableLoading: state => state.isTableLoading,
  isCustomersTableLoading: state => state.isCustomersTableLoading,
  isFormCanceling: state => state.isFormCanceling,
  trailer_types: state => state.trailer_types,
  orderDocumentTypes: state => state.orderDocumentTypes,
  regions: state => state.regions,
  selectedCompanies: state => state.selectedCompanies,
};

export const mutations = {
  setData(state, payload) {
    state.companiesData = payload;
  },
  setFiltersLabels(state, payload) {
    state.filtersLabels = payload;
    state.customerDocModelName = payload.labels.models.customerModelName;
    state.customerDocCollectionName = payload.labels.company.media
      && payload.labels.company.media.customer_docs.id;
    state.transporterClientModelName = payload.labels.models.transporterClientModelName;
    state.documentMainModelName = payload.labels.models.documentMainModelName;
    state.companyModelName = payload.labels.models.companyModelName;
    state.mainContractModelName = payload.labels.models.documentMainModelName;
    state.trailer_types = payload.labels.company.trailer_types;
    state.orderDocumentTypes = Object.values(payload.labels.documentTypes || {});
    state.regions = payload.labels.company.regions;
  },
  saveQuery(state, payload) {
    state.query = payload;
  },
  deleteQuery(state) {
    state.query = {};
  },
  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },
  setFileLoaderProps(state, payload) {
    state.fileLoaderProps = payload;
  },
  setCompanyFullName(state, payload) {
    state.companyFullName = payload;
  },
  setTransporterCustomersData(state, payload) {
    state.transporterCustomersData = {
      ...payload,
      data: payload.data.map(customer => ({
        ...customer,
        media: {
          ...customer.media,
          main_contract: [
            ...Object.values(customer.media.main_contract).map(contract => ({
              ...contract,
              ...contract.file,
              link: {
                url: contract.url,
                fullUrl: contract.fullUrl,
                preview: contract.preview,
              },
            })),
          ],
        },
      })),
    };
  },
  clearTransporterCustomersData(state) {
    state.transporterCustomersData = {};
  },
  setIsClientPageProp(state, payload) {
    state.isClientPage = payload;
  },
  setCompanyCurrentTab(state, payload) {
    state.companyCurrentTab = payload;
  },
  setLegalCheckComments(state, payload) {
    state.legalCheckComments = payload;
  },
  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },
  setCustomersTableLoading(state, payload) {
    state.isCustomersTableLoading = payload;
  },
  setIsFormCanceling(state, payload) {
    state.isFormCanceling = payload;
  },
  setCustomerScansUploaded(state, payload) {
    state.transporterCustomersData.data[payload.index].media.main_contract = payload.docs;
  },
  setSelectedCompanies(state, payload) {
    state.selectedCompanies = payload;
  },
  setTransporterCustomerInsuranceRate(state, { contract_id, newInsuranceRate }) {
    const customer = state.transporterCustomersData.data
      .find(customer => customer.contract?.id === contract_id);

    if (customer) {
      customer.contract = {
        ...customer.contract,
        insurance_rate: newInsuranceRate,
      };
    }
  },
};

let companyCancelToken;

export const actions = {
  async getCompanies({ commit, state }, params = {}) {
    let url = '';

    if (typeof companyCancelToken !== typeof undefined) {
      companyCancelToken.cancel('Operation canceled due to new request.');
    }

    companyCancelToken = this.$axios.CancelToken.source();

    commit('setTableLoading', true);
    const filters = { ...state.query };

    switch (params.role) {
      case 'branches': {
        url = '/companies/branches';
        break;
      }
      default: {
        url = '/companies/';
      }
    }

    await this.$axios.get(url, {
      params: { ...filters, ...params },
      cancelToken: companyCancelToken.token,
    })
      .then(({ data }) => {
        commit('setData', data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async getFiltersLabels({ commit }, { isProfilePage } = {}) {
    const rightPath = isProfilePage ? 'companyprofile/labels' : 'companies/labels';

    await this.$axios.get(rightPath)
      .then(({ data }) => {
        commit('setFiltersLabels', data);
      });
  },

  async deleteCompany({ dispatch }, id) {
    await this.$axios.delete(`/companies/${id}`);
  },

  async loadTransporterCustomers({ commit, state }, payload) {
    commit('setCustomersTableLoading', true);
    await this.$axios.get('/transporter-client', {
      params: {
        transporter_id: payload.transporterId,
        ...payload,
      },
    })
      .then(({ data }) => {
        commit('setTransporterCustomersData', data);
      }).catch(error => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => commit('setCustomersTableLoading', false), 0);
      });
  },

  addTransporterCustomer({ dispatch }, payload) {
    return this.$axios.post('transporter-client', {
      transporter_id: payload.transporterId,
      client_id: payload.clientId,
    });
  },

  async updateTransporterCustomerInsuranceRate({ dispatch }, payload) {
    await this.$axios.post('/companies/insurance-rate', {
      ...payload,
    }).catch(error => {
      console.log(error);
    });
  },

  deleteTransporterCustomer({ dispatch }, rowId) {
    return this.$axios.delete(`transporter-client/${rowId}`);
  },

  async deleteCustomer({ dispatch }, payload) {
    await this.$axios.delete(`customers/delete/${payload.clientId}/${payload.companyId}`);
  },

  async deleteForwarder({ dispatch }, payload) {
    await this.$axios.delete(`forwarders/delete/${payload.companyId}/${payload.clientId}`);
  },

  async getTemporaryMediaData({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },

  async changeStatus({ dispatch }, payload) {
    await this.$axios.post(`/companies/${payload.modelId}/status`, payload)
      .catch(err => {
        console.log(err);
      });
  },

  async fetchLegalCheckComments({ commit }, payload) {
    await this.$axios.get(`/media/comments?type=${payload.type}`)
      .then(({ data }) => {
        commit('setLegalCheckComments', data.legal_check_comments);
      })
      .catch(err => {
        console.log(err);
      });
  },

  async postLegalCheckComment({ commit }, payload) {
    await this.$axios.post('/media/comments', {
      ...payload,
    })
      .catch(err => {
        console.log(err);
      });
  },

  async getSelectedCompanies({ commit }, params) {
    await this.$axios.get('companies/search', { params })
      .then(({ data }) => {
        commit('setSelectedCompanies', data.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('saveQuery', payload);
  },
  deleteQuery({ commit }) {
    commit('deleteQuery');
  },

  changeLegalCheckStatus(_, payload) {
    this.$axios.post(`companies/${payload.id}/legal-check-status`, payload)
      .catch(err => {
        console.log(err);
      });
  },
};
