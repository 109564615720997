export const state = () => ({
  groups: [],
  isTableLoading: false,
  total: 0,
  perPage: 0,
  currentPage: 0,
  query: {},
});

export const getters = {
  getGroups: state => state.groups,
  isTableLoading: state => state.isTableLoading,
  getPagination: state => ({
    total: state.total,
    currentPage: state.currentPage,
    perPage: state.perPage,
  }),
};

export const mutations = {
  setGroups(state, payload) {
    state.groups = payload.data;
    state.total = payload.total;
    state.perPage = payload.per_page;
    state.currentPage = payload.current_page;
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },
};

export const actions = {
  createGroup(context, payload) {
    return this.$axios.post('/groups', payload);
  },

  async loadGroups({ commit, state }, params) {
    commit('setTableLoading', true);

    const filters = { ...state.query };

    const { data } = await this.$axios.get('/groups', { params: { ...filters, ...params } });
    commit('setGroups', data.groups);
    commit('setTableLoading', false);
  },

  updateGroup(context, { id, group }) {
    return this.$axios.put(`/groups/${id}`, group);
  },

  async deleteGroup({ commit }, id) {
    await this.$axios.delete(`/groups/${id}`);
  },
};
