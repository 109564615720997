<template>
  <ul
    v-if="!noLiSurround"
    class="t-pagination"
  >
    <li
      v-if="firstLastButton"
      :class="[pageClass, firstPageSelected() ? disabledClass : '']"
    >
      <a
        :class="pageLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        @click="selectFirstPage()"
        @keyup.enter="selectFirstPage()"
        v-html="firstButtonText"
      />
    </li>
    <li
      v-if="!(firstPageSelected() && hidePrevNext)"
      :class="[prevClass, firstPageSelected() ? disabledClass : '']"
    >
      <a
        :class="prevLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        @click="prevPage()"
        @keyup.enter="prevPage()"
      >
        <slot name="prev">
          <TIconSvg
            name="arrow-left"
            width="7"
            height="12"
            view-box="0 0 7 12"
          />
        </slot>
      </a>
    </li>
    <li
      v-for="page in pages"
      :key="page.vueKey"
      :class="[
        pageClass,
        page.selected ? activeClass : '',
        page.disabled ? disabledClass : '',
        page.breakView ? breakViewClass: ''
      ]"
    >
      <a
        v-if="page.breakView"
        :class="[pageLinkClass, breakViewLinkClass]"
        tabindex="0"
      >
        <slot name="breakViewContent">
          {{breakViewText}}
        </slot>
      </a>
      <a
        v-else-if="page.disabled"
        :class="pageLinkClass"
        tabindex="0"
      >
        {{page.content}}
      </a>
      <a
        v-else
        :class="pageLinkClass"
        tabindex="0"
        @click="handlePageSelected(page.index + 1)"
        @keyup.enter="handlePageSelected(page.index + 1)"
      >
        {{page.content}}
      </a>
    </li>
    <li
      v-if="!(lastPageSelected() && hidePrevNext)"
      :class="[nextClass, lastPageSelected() ? disabledClass : '']"
    >
      <a
        :class="nextLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        @click="nextPage()"
        @keyup.enter="nextPage()"
      >
        <slot name="next">
          <TIconSvg
            name="arrow-right"
            width="7"
            height="12"
            view-box="0 0 7 12"
          />
        </slot>
      </a>
    </li>
    <li
      v-if="firstLastButton"
      :class="[pageClass, lastPageSelected() ? disabledClass : '']"
    >
      <a
        :class="pageLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        @click="selectLastPage()"
        @keyup.enter="selectLastPage()"
        v-html="lastButtonText"
      />
    </li>
  </ul>
  <div
    v-else
    class="t-pagination"
  >
    <a
      v-if="firstLastButton"
      :class="[pageLinkClass, firstPageSelected() ? disabledClass : '']"
      tabindex="0"
      @click="selectFirstPage()"
      @keyup.enter="selectFirstPage()"
      v-html="firstButtonText"
    />
    <a
      v-if="!(firstPageSelected() && hidePrevNext)"
      :class="[prevLinkClass, firstPageSelected() ? disabledClass : '']"
      tabindex="0"
      @click="prevPage()"
      @keyup.enter="prevPage()"
    >
      <slot name="prev">
        <TIconSvg
          name="arrow-left"
          width="7"
          height="12"
        />
      </slot>
    </a>
    <template v-for="page in pages">
      <a
        v-if="page.breakView"
        :key="page.vueKey"
        :class="[pageLinkClass, breakViewLinkClass, page.disabled ? disabledClass : '']"
        tabindex="0"
      >
        <slot name="breakViewContent">
          {{breakViewText}}
        </slot>
      </a>
      <a
        v-else-if="page.disabled"
        :key="page.vueKey"
        :class="[pageLinkClass, page.selected ? activeClass : '', disabledClass]"
        tabindex="0"
      >
        {{page.content}}
      </a>
      <a
        v-else
        :key="page.vueKey"
        :class="[pageLinkClass, page.selected ? activeClass : '']"
        tabindex="0"
        @click="handlePageSelected(page.index + 1)"
        @keyup.enter="handlePageSelected(page.index + 1)"
      >
        {{page.content}}
      </a>
    </template>
    <a
      v-if="!(lastPageSelected() && hidePrevNext)"
      :class="[nextLinkClass, lastPageSelected() ? disabledClass : '']"
      tabindex="0"
      @click="nextPage()"
      @keyup.enter="nextPage()"
    >
      <slot name="next">
        <TIconSvg
          name="arrow-right"
          width="7"
          height="12"
        />
      </slot>
    </a>
    <a
      v-if="firstLastButton"
      :class="[pageLinkClass, lastPageSelected() ? disabledClass : '']"
      tabindex="0"
      @click="selectLastPage()"
      @keyup.enter="selectLastPage()"
      v-html="lastButtonText"
    />
  </div>
</template>

<script>
import routerMixin from '~/assets/js/mixins/routerMixin';

export default {
  name: 'TPagination',

  mixins: [routerMixin],

  props: {
    value: {
      type: Number,
      default: null,
    },

    totalItems: {
      type: Number,
      required: true,
    },

    perPageItems: {
      type: Number,
      required: true,
    },

    forcePage: {
      type: Number,
      default: null,
    },

    pageRange: {
      type: Number,
      default: 3,
    },

    marginPages: {
      type: Number,
      default: 1,
    },

    breakViewText: {
      type: String,
      default: '…',
    },

    containerClass: {
      type: String,
      default: 't-pagination',
    },

    pageClass: {
      type: String,
      default: 'page-item',
    },

    pageLinkClass: {
      type: String,
      default: 'page-link-item',
    },

    prevClass: {
      type: String,
      default: 'prev-item',
    },

    prevLinkClass: {
      type: String,
      default: 'prev-link-item',
    },

    nextClass: {
      type: String,
      default: 'next-item',
    },

    nextLinkClass: {
      type: String,
      default: 'next-link-item',
    },

    breakViewClass: {
      type: String,
      default: 'break-view',
    },

    breakViewLinkClass: {
      type: String,
      default: 'break-view-link',
    },

    activeClass: {
      type: String,
      default: 'active',
    },

    disabledClass: {
      type: String,
      default: 'disabled',
    },

    noLiSurround: {
      type: Boolean,
      default: false,
    },

    firstLastButton: {
      type: Boolean,
      default: false,
    },

    firstButtonText: {
      type: String,
      default: 'First',
    },

    lastButtonText: {
      type: String,
      default: 'Last',
    },

    hidePrevNext: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: 1,
    };
  },

  computed: {
    selected() {
      return this.value || this.innerValue;
    },

    pageCount() {
      return Math.ceil(this.totalItems / this.perPageItems);
    },

    pages() {
      const items = {};

      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };

          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);
        const setPageItem = index => {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };

          items[index] = page;
        };

        const setBreakView = index => {
          const breakView = {
            disabled: true,
            breakView: true,
          };
          items[index] = breakView;
        };

        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }

        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange;
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i);
        }

        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
          setPageItem(i);
        }
      }
      return items;
    },
  },

  beforeUpdate() {
    if (this.forcePage === undefined) {
      return;
    }

    if (this.forcePage !== this.selected) {
      this.innerValue = this.forcePage;
    }
  },

  methods: {
    handlePageSelected(selected) {
      if (this.selected === selected) {
        return;
      }

      this.setQuery({ ...this.getQuery(), page: selected });
      this.innerValue = selected;
      this.$emit('input', selected);
    },

    prevPage() {
      if (this.selected <= 1) {
        return;
      }

      this.handlePageSelected(this.selected - 1);
    },

    nextPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.handlePageSelected(this.selected + 1);
    },

    firstPageSelected() {
      return this.selected === 1;
    },

    lastPageSelected() {
      return (this.selected === this.pageCount) || (this.pageCount === 0);
    },

    selectFirstPage() {
      if (this.selected <= 1) {
        return;
      }

      this.handlePageSelected(1);
    },

    selectLastPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.handlePageSelected(this.pageCount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/_variables";

.t-pagination {
  display: flex;

  .page-item {
    margin-right: 8px;
    max-width: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid $light-grey;
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }

  .next-link-item,
  .prev-link-item,
  .page-link-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .next-link-item {
    padding-left: 2px;
    padding-top: 2px;
  }

  .prev-link-item {
    padding-right: 2px;
    padding-top: 2px;
  }

  .prev-item {
    max-width: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-right: 8px;
    border: 1px solid $light-grey;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    background-color: white;
  }

  .next-item {
    max-width: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid $light-grey;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    background-color: #FFF;
  }

  .break-view {
    border: none;
    min-width: 0;
    background-color: transparent;
  }

  .active {
    border: none;
    background-color: $golden-yellow;
  }

  .disabled {
    cursor: default;
    outline: none;
  }

  li {
    list-style: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }
}
</style>
