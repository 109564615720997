import { removeSeconds } from '~/assets/js/utils';

export const state = () => ({
  ordersData: {},
  filtersLabels: {},
  query: {},
  isTableLoading: false,
  isOrderCopied: false,
});

export const getters = {
  list: state => state.ordersData.data,
  pagination: state => ({
    perPage: state.ordersData.per_page,
    total: state.ordersData.total,
    currentPage: state.ordersData.current_page,
  }),
  filtersLabels: state => state.filtersLabels,
  query: state => state.query,
  isTableLoading: state => state.isTableLoading,
  isOrderCopied: state => state.isOrderCopied,
};

export const mutations = {
  setData(state, payload) {
    state.ordersData = {
      ...payload.links,
      ...payload.meta,
      data: payload.data.map(order => ({
        ...order,
        loading_time_from: removeSeconds(order.loading_time_from),
        loading_time_to: removeSeconds(order.loading_time_to),
        unloading_time_from: removeSeconds(order.unloading_time_from),
        unloading_time_to: removeSeconds(order.unloading_time_to),
      })),
    };
  },

  setFiltersLabels(state, payload) {
    state.filtersLabels = payload;
  },

  saveQuery(state, payload) {
    state.query = payload;
  },

  setOrderToTop(state, payload) {
    state.ordersData.data.unshift(payload);
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setIsOrderCopied(state, payload) {
    state.isOrderCopied = payload;
  },
};

export const actions = {
  async getOrders({ commit, state }, params) {
    commit('setTableLoading', true);
    let filters = {};

    if (Number.isInteger(Number(params))) {
      filters = {
        ...state.query,
        page: params,
      };
    } else {
      filters = {
        ...state.query,
        ...params,
      };
    }

    await this.$axios.get('/orders', { params: { ...filters } })
      .then(({ data }) => {
        commit('setData', data);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async getFiltersLabels({ commit }) {
    await this.$axios.get('/orders/labels')
      .then(({ data }) => {
        commit('setFiltersLabels', data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async delete({ commit }, id) {
    await this.$axios.post('/orders/delete', { id })
      .then(({ data }) => data.success);
  },

  saveQuery({ commit, dispatch }, payload) {
    return commit('saveQuery', payload);
  },
};
