<template>
  <AppEntry>
    <div class="class">
      <div class="sidebar-layout">
        <Sidepanel />
        <div class="sidebar-layout__content">
          <DocumentViewer />
          <nuxt />
        </div>
      </div>
    </div>
  </AppEntry>
</template>

<script>
import Sidepanel from '../components/Features/Sidepanel';
import DocumentViewer from '~/components/Features/DocumentViewer/DocumentViewer';
import AppEntry from '~/AppEntry';

export default {
  components: {
    AppEntry,
    DocumentViewer,
    Sidepanel,
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.sidebar-layout {
  display: flex;

  &__content {
    position: relative;
    min-width: $min-main-content-width;
    flex: 1;
  }
}
</style>
