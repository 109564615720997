<template>
  <div class="t-popup">
    <div
      v-if="hasOverlay"
      class="t-popup__overlay"
      @click="overlayClose ? $emit('close') : ''"
    />
    <div
      class="t-popup__wrapper"
      @click.stop
    >
      <div
        :style="{ width }"
        class="t-popup__content"
      >
        <div class="t-popup__header">
          <slot name="header" />
          <button
            v-if="hasCloseButton"
            type="button"
            class="t-popup__close-button"
            @click="$emit('close')"
          >
            <TIconSvg
              name="close"
              fill="#C4C4C4"
              stroke="none"
              height="14"
              width="14"
              view-box="0 0 11 11"
            />
          </button>
        </div>
        <div class="t-popup__text">
          <slot />
        </div>
        <div class="t-popup__bottom">
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPopup',

  props: {
    hasOverlay: {
      type: Boolean,
      default: true,
    },

    overlayClose: {
      type: Boolean,
      default: false,
    },

    hasCloseButton: {
      type: Boolean,
      default: true,
    },

    width: {
      type: String,
      default: '495px',
    },

    preventScrolling: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.preventScrolling) {
      document.body.style.overflow = 'hidden';
    }
  },

  beforeDestroy() {
    if (this.preventScrolling) {
      document.body.style.overflow = 'visible';
    }
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.t-popup {
  position: fixed;
  @include fullScreen;
  overflow: hidden;
  z-index: 400;

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__bottom {
    position: sticky;
    bottom: -1px;
    background-color: #fff;
    z-index: 5;
    padding-top: 4px;
    padding-bottom: 32px;
  }

  &__header {
    position: sticky;
    top: -1px;
    background-color: #fff;
    z-index: 5;
    padding-top: 26px;
    padding-bottom: 4px;

    .t-heading {
      margin-bottom: 4px;
    }
  }

  &__content {
    @include centerAbsoluteXY;
    background-color: #FFF;
    box-shadow: $shadow-black;
    max-height: 90vh;
    overflow: auto;
  }

  & &__close-button {
    position: absolute;
    top: 30px;
    right: -20px;
    cursor: pointer;
  }

  @media (min-width: $media-xs) {
    padding: 0;
    overflow: hidden;

    &__content {
      padding: 0 44px !important;
    }
  }
}
</style>
