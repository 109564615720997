<template>
  <label
    class="t-switch"
    :class="{
      't-switch--checked': value,
      't-switch--disabled': disabled,
      't-switch--error': hasError,
    }"
  >
    <input
      :id="id"
      ref="input"
      v-model="value"
      type="checkbox"
      class="t-switch__input"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    >
  </label>
</template>

<script>
export default {
  name: 'TSwitch',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.$refs.input.checked = this.value;
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

  .t-switch {
    height: 16px;
    width: 32px;
    position: relative;
    margin-bottom: 0;
    display: block;
    cursor: pointer;

    input {
      margin-top: 0;
      padding: 0;
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &:before {
      background: $white-light-grey;
      border-radius: 16px;
      border: 1px solid $light-grey;
      content: '';
      height: 16px;
      top: 0px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 32px;
      left: 0px;
    }

    &:after {
      content: '';
      border: 1px solid $light-grey;
      box-shadow: 2px 2px 2px rgba(157, 157, 157, 0.16);
      background: rgb(255, 255, 255);
      border-radius: 16px;
      height: 16px;
      top: 0px;
      left: 0px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 16px;
    }

    &--checked {
      &:before {
        color: #fff;
        border-color: $golden-yellow;
        background-color: $golden-yellow;
      }

      &:after {
        left: 16px;
        border-color: $golden-yellow;
      }
    }

    &--disabled {
      opacity: 0.55;
    }
  }
</style>
