export const dict = {
  custom: {
    phone: {
      regex: () => 'Please enter a valid phone number',
    },
    number: {
      regex: () => 'Please enter a valid number',
    },
    password: {
      min: () => 'Wrong password',
    },
    sts: {
      required: () => 'Add file',
    },
    docs: {
      required: () => 'Add file',
    },
    agreement_documents: {
      required: () => 'Upload file',
    },
    instant_price: {
      min_value: (field, [min, value]) => `The rate must be higher than ${min - 1}${value || ''}`,
    },
    generalLimit: {
      max_value: (field, [max, value]) => (!max
        ? 'Indicate the amount of cargo'
        : `The field must be ${max} ${value || ''} or less`),
    },
  },
};

export default {
  between: (field, [min, max]) => `The field must be at least ${min} characters long but not more then ${max}`,
  email: () => 'Please, enter the valid email',
  max: (field, [length]) => `The field must be not more then ${length} characters long`,
  max_value: (field, [max, value]) => `The field must be not more then ${max}${value || ''}  characters long`,
  min: (field, [length]) => `The field must be at least ${length} characters long`,
  min_value: (field, [min, value]) => `The field must be at least ${min}${value || ''} characters long or more`,
  numeric: () => 'The field must be a number',
  regex: () => 'The format is invalid',
  required: () => 'Please fill in the field',
  length: (field, [size]) => `The field must contain ${size} characters`,
  inn: (field, [innLength1, innLength2]) => `The field must contain ${innLength1} or ${innLength2} digits`,
  time_period: () => 'End time must be later than start time',
};
