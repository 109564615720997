export const state = () => ({
  personalSettings: {},
  accreditationRequirements: [],
  labels: {},
});

export const getters = {
  getPersonalSettings: state => state.personalSettings,
};

export const mutations = {
  SET_PERSONAL_SETTINGS(state, payload) {
    state.personalSettings = payload;
  },
  SET_LABELS(state, payload) {
    state.labels = payload;
  },
};

export const actions = {
  async loadPersonalSettings({ rootGetters, commit }) {
    const companyId = rootGetters['main/getUserActiveCompanyId'];

    try {
      const { data: { settings, labels } } = await this.$axios.get(`/companies/${companyId}/client-settings`);

      commit('SET_PERSONAL_SETTINGS', settings);
    } catch (error) {
      console.log(error);
    }
  },
};
