<template>
  <div
    class="t-field t-multiselect"
    :class="{
      't-field--no-label': !hasLabel,
      't-field--loading': isLoading,
      't-field--error': hasErrors,
      't-field--success': !hasErrors && hasSuccessMessages,
      't-field--ellipsis': isEllipsis
    }"
  >
    <span
      v-if="hasLabel"
      class="t-field__label"
      :class="{
        't-field__label--error': hasErrors,
        't-field__label--success': !hasErrors && hasSuccessMessages,
        required,
      }"
    >
      {{label}}
    </span>
    <div
      ref="container"
      class="t-field__field-wrapper"
      :class="{'multiselect__is-list-hidden': isListHidden}"
    >
      <Component
        :is="isDataAccessible ? 'Multiselect' : 'TInput'"
        ref="multiselect"
        :value="value"
        :options="allowSelectAll ? groupOptions : items"
        :placeholder="placeholderText"
        :select-label="selectLabel"
        :selected-label="selectedLabel"
        :deselect-label="deselectLabel"
        :show-labels="showLabels"
        :track-by="isStringItems ? undefined : itemValue"
        :label="isStringItems || !isDataAccessible ? undefined : itemText"
        :disabled="disabled"
        :multiple="multiple"
        :internal-search="internalSearch"
        :close-on-select="closeOnSelect"
        :clear-on-select="clearOnSelect"
        :group-select="allowSelectAll"
        :group-label="allowSelectAll ? 'label' : ''"
        :group-values="allowSelectAll ? 'values' : ''"
        :loading="isSelectLoading"
        :searchable="searchable || route"
        :allow-empty="allowEmpty"
        :hide-selected="hideSelected"
        :reset-after="resetAfter"
        :taggable="taggable"
        :limit="limit"
        :preselect-first="preselectFirst"
        :preserve-search="preserveSearch"
        :max-height="maxHeigth"
        @search-change="handleRequest"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        @remove="clearValue"
        @select="$emit('select', $event)"
        @close="$emit('close', $event)"
      >
        <slot
          v-if="$slots.noResult || noResultText"
          slot="noResult"
        >
          {{!route ? noResultText : searchStatusText}}
        </slot>
        <slot slot="noOptions">
          {{noOptionsText}}
        </slot>
        <slot slot="caret">
          <div class="multiselect__select">
            <TIconSvg
              name="arrow-down"
              view-box="0 0 512 512"
              fill="#5E6979"
              height="12"
              width="12"
            />
          </div>
        </slot>
        <slot slot="limit">
          <div class="multiselect__limit">
            {{limitText}}
          </div>
        </slot>
        <div
          v-if="allowSelectAll && value.length === items.length"
          slot="selection"
        >
          {{allSelectedOptionsText}}
        </div>
        <template
          v-if="multiple"
          slot="beforeList"
        >
          <div
            v-if="value && value.length"
            class="multiselect__tags-wrap--before"
          >
            <span
              v-for="(tagName, index) in tagNames"
              :key="tagName.vueKey"
              class="multiselect__tag"
            >
              <span>
                {{tagName}}
              </span>
              <i
                class="multiselect__tag-icon"
                @click="unselectTag(index)"
              />
            </span>
          </div>
        </template>
      </Component>
    </div>
    <div
      v-if="hasErrors"
      class="t-field__hint t-field__hint--error j-error"
    >
      {{errorMessages[0]}}
    </div>
    <div
      v-if="!hasErrors && hasSuccessMessages"
      class="t-field__hint t-field__hint--success-message"
    >
      {{Array.isArray(successMessages) ? successMessages[0] : successMessages}}
    </div>
    <div
      v-if="hint && !hasErrors && !hasSuccessMessages"
      class="t-field__hint"
    >
      {{hint}}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
  isObject,
  isEmpty,
  isString,
  isArray,
} from 'chober';

// libs
import { v4 as uuid } from 'uuid';
import dadata from '~/assets/js/dadata';

const REQUEST_TIMEOUT = 1000;

export default {
  name: 'TMultiselect',

  components: {
    Multiselect,
  },

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    route: {
      type: String,
      default: null,
    },

    showLabels: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isListHidden: {
      type: Boolean,
      default: false,
    },

    taggable: {
      type: Boolean,
      default: false,
    },

    preselectFirst: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    hideSelected: {
      type: Boolean,
      default: false,
    },

    resetAfter: {
      type: Boolean,
      default: false,
    },

    clearOnSelect: {
      type: Boolean,
      default: true,
    },

    thresholdLimit: {
      type: Number,
      default: 3,
    },

    hint: {
      type: String,
      default: null,
    },

    noResult: {
      type: String,
      default: '_default',
    },

    noOptions: {
      type: String,
      default: '_default',
    },

    placeholder: {
      type: String,
      default: '_default',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    allowSelectAll: {
      type: Boolean,
      default: false,
    },

    selectLabel: {
      type: String,
      default: '',
    },

    selectedLabel: {
      type: String,
      default: '',
    },

    deselectLabel: {
      type: String,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    internalSearch: {
      type: Boolean,
      default: false,
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    routeResponseProp: {
      type: String,
      default: '',
    },

    queryParam: {
      type: String,
      default: 'search',
    },

    additionalParams: {
      type: Array,
      default: () => [],
    },

    limit: {
      type: Number,
      default: undefined,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isDadata: {
      type: Boolean,
      default: false,
    },

    daDataOptions: {
      type: Object,
      default: () => ({}),
    },

    daDataType: {
      type: String,
      default: 'address',
    },

    additionalSearch: {
      type: String,
      default: '',
    },

    isInputAfterDadataFail: {
      type: Boolean,
      default: true,
    },

    isEmitInputInDadataSearch: {
      type: Boolean,
      default: false,
    },

    isSafeEmitInputInDadataSearch: {
      type: Boolean,
      default: false,
    },

    preserveSearch: {
      type: Boolean,
      default: false,
    },

    allSelectedOptionsText: {
      type: String,
      default: 'Все',
    },

    maxHeigth: {
      type: Number,
      default: 240,
    },

    rowIndex: {
      type: Number,
      default: 0,
    },

    required: {
      type: Boolean,
      default: false,
    },

    isEllipsis: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isLoading: false,
    searchStatusText: '',
    abort: null,
    requestTimeout: null,
    isDataAccessible: true,
  }),

  computed: {
    isSelectLoading() {
      return this.isLoading || this.loading;
    },

    hasLabel() {
      return Boolean(this.label);
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },

    isObjectItems() {
      return this.items.length !== 0 && isObject(this.items[0]);
    },

    isObjectValues() {
      if (!isArray(this.value)) return false;
      return this.value.length !== 0 && isObject(this.value[0]);
    },

    isStringItems() {
      return this.items.length !== 0 && isString(this.items[0]);
    },

    groupOptions() {
      return [{
        label: this.$t('All'),
        values: this.items,
      }];
    },

    placeholderText() {
      if (this.placeholder === '_default') {
        return this.$t('Select from the list');
      }

      return this.placeholder;
    },

    noOptionsText() {
      if (this.noOptions === '_default') {
        return this.$t('The list is empty');
      }

      return this.noOptions;
    },

    noResultText() {
      if (this.noResult === '_default') {
        return this.$t('The search has not given any results');
      }

      return this.noResult;
    },

    limitText() {
      if (typeof this.limit === 'number' && this.value) {
        const count = this.value.length - this.limit;
        return `+${count}`;
      }

      return '';
    },

    tagNames() {
      if (!isArray(this.value)) return [];
      if (this.isObjectItems || this.isObjectValues) {
        return this.value.map(tag => tag[this.itemText] || {});
      }

      return this.value;
    },
  },

  watch: {
    value(value) {
      if (!value) {
        this.isDataAccessible = true;
      }
    },
  },

  beforeDestroy() {
    if (this.isDataAccessible && this.$refs.multiselect) {
      this.$refs.multiselect.deactivate();
    }
  },

  methods: {
    unselectTag(index) {
      if (this.multiple) {
        const localValue = this.value.slice();

        localValue.splice(index, 1);
        this.$emit('input', localValue);
      }
    },

    clearValue() {
      if (isEmpty(this.value)) {
        if (this.multiple) {
          this.$emit('input', []);
        } else {
          this.$emit('input', null);
        }
      }
      this.$emit('remove');
    },

    async handleRequest(input) {
      this.$emit('search-change', input);

      if (
        (!this.route && !this.isDadata)
        || !input
        || input.length <= this.thresholdLimit
      ) {
        this.searchStatusText = this.noOptions;
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
          this.isLoading = false;
        }
        return;
      }

      this.isLoading = true;
      let items = [];

      try {
        items = await this[this.isDadata ? 'loadDadata' : 'loadData'](input);
      } catch (error) {
        this.$emit('change-options-error', error);
      }

      this.isLoading = false;
      this.searchStatusText = this.noResultText;

      if (this.isDadata && !this.isSafeEmitInputInDadataSearch) {
        this.$emit(
          'change-options',
          this.isEmitInputInDadataSearch
            ? { suggestions: [...items.suggestions, { name: input, id: uuid(), custom: true }] }
            : items,
        );
      } else {
        this.$emit(
          'change-options',
          (this.isEmitInputInDadataSearch || this.isSafeEmitInputInDadataSearch)
            ? [items, input]
            : items,
          this.rowIndex,
        );
      }
    },

    formatResponseData(routeResponseProps, data) {
      const localVariables = routeResponseProps.slice();
      const option = localVariables.shift();

      if (localVariables.length > 0) {
        return this.formatResponseData(localVariables, data[option]);
      }

      return data[option];
    },

    loadDadata(input) {
      return new Promise((resolve, reject) => {
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
        }

        if (!input) return Promise.resolve({
          suggestions: [],
        });

        this.requestTimeout = setTimeout(() => {
          const query = this.additionalSearch ? `${this.additionalSearch}, ${input}` : input;

          dadata(query, this.daDataType, this.daDataOptions, this.$i18n.locale)
            .then(result => {
              resolve(result);
            })
            .catch(error => {
              if (navigator.onLine) {
                this.isDataAccessible = !this.isInputAfterDadataFail;
              }

              this.$emit('dadata-error', error);
              this.$emit('input', input);
              resolve({
                suggestions: [],
              });
            });
        }, REQUEST_TIMEOUT);
      });
    },

    loadData(input) {
      return new Promise((resolve, reject) => {
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
        }

        const { CancelToken } = this.$axios;

        this.requestTimeout = setTimeout(() => {
          if (typeof this.abort === 'function') {
            this.abort();
          }

          const additionalParams = this.additionalParams.reduce((acc, cur) => ({
            ...acc,
            [cur.param]: cur.query,
          }), {});

          this.$axios.get(`${this.route}`, {
            params: {
              [this.queryParam]: input,
              ...additionalParams,
            },
            cancelToken: new CancelToken(abort => {
              this.abort = abort;
            }),
          }).then(({ data }) => {
            const routeResponseProps = this.routeResponseProp.split('.');

            resolve(this.formatResponseData(routeResponseProps, data));
          }).catch(reject)
            .finally(this.abort = null);
        }, REQUEST_TIMEOUT);
      });
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.multiselect {
  $self: &;

  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: $default-control-height;
  text-align: left;
  color: #35495e;

  &__is-list-hidden {
    #{$self}__content-wrapper {
      display: none;
    }
  }

  &--active {
    z-index: 100;

    #{$self}__limit,
    #{$self}__tags-wrap {
      display: none;
    }
  }

  &__limit {
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    padding: 1px 4px;
    background-color: $light-yellow;
    margin-top: 2px;
  }

  &__tags-wrap {
    display: inline;
    width: 100%;

    &--before {
      border-bottom: 1px solid #7F828B;
      padding-bottom: 4px;
      padding-left: 4px;
      min-height: 28px;
      max-height: 140px;
      overflow-y: scroll;
      position: sticky;
      top: 0;
      background-color: #FFF;
      z-index: 201;
    }
  }

  &__tag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 2px 20px 2px 10px;
    margin: 0 2px 2px 0;
    line-height: 1;
    font-size: 12px;
    border-radius: 30px;
    background: $light-yellow;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  &__tag-icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 700;
    font-style: initial;
    width: 18px;
    text-align: center;
    line-height: 18px;
    transition: all 0.2s ease;
    border-radius: 3px;

    &:after {
      content: '✕';
      color: $light-brown-grey;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus:after,
    &:hover:after {
      color: $steel-grey;
    }
  }

  &__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: flex;
    vertical-align: top;
  }

  &__tags {
    min-height: 32px;
    display: flex;
    padding: 0 40px 0 8px;
    border: 1px solid #D4D9DC;
    background: #fff;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
  }

  &__select {
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    width: 40px;
    height: 100%;
    right: 1px;
    top: 1px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  &__input,
  &__single {
    position: relative;
    display: inline-block;
    min-height: 16px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0;
    width: 100%;
    transition: border 0.1s ease;
    box-sizing: border-box;
    vertical-align: top;
  }

  &__option {
    display: block;
    padding: 4px 10px 4px;
    min-height: 20px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: normal;

    &:after {
      top: 0;
      right: 0;
      position: absolute;
      line-height: 32px;
      padding-right: 12px;
      padding-left: 20px;
      font-size: 13px;
    }

    &--highlight {
      background: $white-grey;
      outline: none;

      &:after {
        content: attr(data-select);
        background: #41b883;
        color: white;
      }
    }

    &--selected {
      background: #f3f3f3;
      color: #35495e;
      font-weight: 600;

      &:after {
        content: attr(data-selected);
        color: silver;
      }
    }

    &--selected &--highlight {
      background: #ff6a6a;
      color: #fff;

      &:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
      }
    }

    &--disabled {
      background: #ededed !important;
      color: #a6a6a6 !important;
      cursor: text;
      pointer-events: none;
    }

    &--group {
      background: #ededed;
      color: #35495e;
    }

    &--group &--highlight {
      background: #35495e;
      color: #fff;

      &:after {
        background: #35495e;
      }
    }

    &--disabled &--highlight {
      background: #dedede;
    }

    &--group-selected &--highlight {
      background: #ff6a6a;
      color: #fff;

      &:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
      }
    }
  }

  &__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 48px;
    background: #fff;
    display: block;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border-color: #41b883 transparent transparent;
      border-style: solid;
      border-width: 2px;
      box-shadow: 0 0 0 1px transparent;
    }

    &:before {
      animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
      animation-iteration-count: infinite;
    }

    &:after {
      animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
      animation-iteration-count: infinite;
    }
  }

  &--desabled &__current,
  &--desabled &__select {
    background: #ededed;
    color: #a6a6a6;
  }

  &__loading-enter-active,
  &__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  &__loading-enter,
  &__loading-leave-active {
    opacity: 0;
  }

  &,
  &__input,
  &__single {
    font-family: inherit;
    touch-action: manipulation;
  }

  & * {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }

  &--disabled {
    background: #ededed;
    pointer-events: none;
    opacity: 0.6;
  }

  &--active:not(&--above) &__current,
  &--active:not(&--above) &__input,
  &--active:not(&--above) &__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__input,
  &__single {
    position: relative;
    display: inline-block;
    border: none;
    border-radius: 5px;
    background: #fff;
    width: calc(100%);
    transition: border 0.1s ease;
    box-sizing: border-box;
    vertical-align: top;
  }

  &__current {
    line-height: 16px;
    min-height: 32px;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    margin: 0;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    cursor: pointer;
  }

  &__placeholder {
    color: #adadad;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #D4D9DC;
    box-shadow: 4px 4px 10px $almost-black-shadow;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 200;
    -webkit-overflow-scrolling: touch;
  }

  &__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }

  &--above &__content-wrapper {
    bottom: 100%;
    border-radius: 2px 2px 0 0;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
  }

  &--active &__select {
    transform: rotateZ(180deg);
  }

  &--above &--active &__current,
  &--above &--active &__input,
  &--above &--active &__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__input {
    &::placeholder {
      color: #35495e;
    }
  }

  &__tag ~ &__input,
  &__tag ~ &__single {
    width: auto;
  }

  &__input,
  &__single {
    &:hover {
      border-color: #cfcfcf;
    }

    &:focus {
      border: 0px;
    }
  }

  &__single {
    padding-left: 5px;
  }

  &--active &__placeholder {
    display: none;
  }

  &--active .multiselect__tags {
    border: 1px solid $light-blue;
  }

  &__content::-webkit-scrollbar {
    display: none;
  }

  &__element {
    display: block;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease;
  }

  &-enter,
  &-leave-active {
    opacity: 0;
  }

  fieldset[disabled] & {
    pointer-events: none;
  }
}

.t-multiselect.t-field--error .multiselect__tags {
  border: 1px solid $red;
}

.t-multiselect {
  .t-field__field-wrapper > .t-field {
    padding: 0 !important;
  }
}

.t-field--ellipsis{
  .multiselect__input,
  .multiselect__single {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>
