const exeptOuterRoutes = {
  '/password-recovery': true,
  '/login': true,
  '/register': true,
  '/public-orders': true,
  '/unsubscribe': true,
};

const exeptOuterNames = {
  'public-orders-id': true,
};

export default ({ app, store }) => {
  app.router.beforeEach((to, from, next) => {
    store.commit('imageViewer/setVisibility', false);

    if (process.client) {
      const currentScrollPosition = store.getters['general/getPageScrollPosition'];
      let position;

      if (from.matched.length === 1) {
        position = { x: 0, y: window.pageYOffset };
      } else if (to.matched.length > 1) {
        position = { x: 0, y: 0 };
      } else {
        position = currentScrollPosition;
      }

      store.commit('general/setPageScrollPosition', position);
    }

    if (
      !exeptOuterRoutes[to.path]
      && !exeptOuterNames[to.name]
      && exeptOuterRoutes[from.path]
      && !store.getters['general/GET_TOKEN']
    ) {
      next(false);
      app.router.go('/login');
    } else if (
      !exeptOuterRoutes[from.path]
      && exeptOuterNames[to.name]
      && exeptOuterRoutes[to.path]
      && store.getters['general/GET_TOKEN']
    ) {
      next(false);
      app.router.go();
    } else {
      next();
    }
  });
};
