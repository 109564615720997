export const state = () => ({
  pointsList: [],
  timeSlotDuration: null,
  settings: {},
  cargoStationTypes: [],
});

export const getters = {
  getPointsList: state => state.pointsList,
  getTimeSlotDuration: state => state.timeSlotDuration,
  getSettings: state => state.settings,
  getCargoStationTypes: state => state.cargoStationTypes,
};

export const mutations = {
  setPointsList(state, payload) {
    const keys = Object.keys(payload);

    state.pointsList = keys.map(key => ({
      id: Number(key),
      name: payload[key],
    }));
  },

  setCargoStationTypes(state, payload) {
    const keys = Object.keys(payload);

    state.cargoStationTypes = keys.map(key => ({
      id: Number(key),
      name: payload[key],
    }));
  },

  setTimeSlotDuration(state, payload) {
    state.timeSlotDurationList = payload;
  },

  setSettings(state, payload) {
    state.settings = {
      ...payload,
      working_turns: payload.working_turns.map((turn, index) => ({
        ...turn,
        isChecked: true,
        order: index + 1,
      })),
    };
  },
};

export const actions = {
  async loadAdditionalData({ commit }) {
    await this.$axios.get('/settings/edit')
      .then(({ data }) => {
        commit('setPointsList', data.dq_modes);
        commit('setCargoStationTypes', data.cargo_station_types);
      })
      .catch(error => console.log(error));
  },

  async loadSettings({ commit }, payload) {
    await this.$axios.get('/settings')
      .then(({ data }) => {
        commit('setSettings', data.data);
      })
      .catch(error => console.log(error));
  },

  async updateSettings({ commit }, payload) {
    await this.$axios.put('/settings', { ...payload });
  },
};
