var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"t-field",class:{
    't-field--no-label': !_vm.hasLabel,
    't-field--loading': _vm.loading,
    't-field--focused-loading': _vm.canEditLoading && _vm.loading,
  },attrs:{"tag":"div","rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('TInput',_vm._g(_vm._b({attrs:{"error-messages":(_vm.$attrs['error-messages'] || []).concat( errors
    )},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'TInput',Object.assign({}, _vm.$props, _vm.$attrs),false),_vm.$listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }