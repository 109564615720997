<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :rules="validationRules"
    class="t-field"
    :class="{
      't-field--no-label': !hasLabel,
      't-field--loading': loading,
      't-field--focused-loading': canEditLoading && loading,
    }"
  >
    <TInput
      v-model="localValue"
      v-bind="{...$props, ...$attrs}"
      :error-messages="[
        ...($attrs['error-messages'] || []),
        ...errors
      ]"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import { isEmpty } from 'chober';

export default {
  name: 'TInputProvider',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {

    value: {
      type: [String, Number],
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEditLoading: {
      type: Boolean,
      default: false,
    },

    validationRules: {
      type: [String, Object],
      default: null,
    },
  },

  data: () => ({
    localValue: '',
  }),

  computed: {
    hasLabel() {
      return Boolean(this.label);
    },
  },

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
