export default function(to, from, savedPosition) {
  const currentPageScrollPosition = this.app.$store.getters['general/getPageScrollPosition'];

  if (to.path === from.path) {
    return currentPageScrollPosition;
  }

  if (from.matched.length === 1
      || (to.matched.length === 1
        && from.matched.length > 1
        && currentPageScrollPosition.y === 0)) {
    return { x: 0, y: 0 };
  }

  return currentPageScrollPosition;
}
