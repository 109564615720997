export const state = () => ({
  pointList: [],
  statuses: [],
});

export const getters = {
  getPointList: state => state.pointList,
  getPointStatuses: state => state.statuses,
};

export const mutations = {
  setPointList(state, payload) {
    state.pointList = payload;
  },

  setLabels(state, payload) {
    state.statuses = Object.values(payload);
  },
};

export const actions = {
  async loadPointsList({ commit }) {
    await this.$axios.get('/cargo-stations')
      .then(({ data }) => {
        commit('setPointList', data.data);
      })
      .catch(error => console.log(error));
  },

  async loadLabels({ commit }) {
    await this.$axios.get('/cargo-stations/create')
      .then(({ data }) => {
        commit('setLabels', data.statusLabels);
      })
      .catch(error => console.log(error));
  },

  createPoint({ dispatch }, data) {
    return this.$axios.post('/cargo-stations', data)
      .then(() => {
        dispatch('loadPointsList');
        return true;
      });
  },

  updatePoint({ dispatch }, data) {
    return this.$axios.put(`/cargo-stations/${data.id}`, data)
      .then(() => {
        dispatch('loadPointsList');
        return true;
      });
  },

  deletePoint({ dispatch }, id) {
    return this.$axios.delete(`/cargo-stations/${id}`)
      .then(() => {
        dispatch('loadPointsList');
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },
};
