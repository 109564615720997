export const state = () => ({
  comments: [],
});

export const getters = {
  getComments: state => state.comments,
};

export const mutations = {
  setComments(state, payload) {
    state.comments = payload;
  },
};

export const actions = {
  removeFile({ commit }, id) {
    return this.$axios.delete(`/media/${id}`)
      .catch(err => {
        console.log(err);
      });
  },

  removeMultipleFiles({ commit }, params) {
    if (!params.length) {
      return Promise.resolve();
    }

    return this.$axios.post('/media/delete-block', {
      mediaIds: [...params],
    })
      .then(data => data);
  },

  restoreMultipleFiles({ commit }, params) {
    if (!params.length) {
      return Promise.resolve();
    }

    return this.$axios.post('/media/restore', {
      mediaIds: [...params],
    });
  },

  updateFileStatus({ commit }, params) {
    return this.$axios.post('/media/status', params)
      .catch(err => {
        console.log(err);
      });
  },

  async sendComment({ commit }, params) {
    const { route } = params;
    await this.$axios.post(route || '/media/comments', params)
      .catch(err => {
        console.log(err);
      });
  },

  async loadComments({ commit }, params) {
    const { route } = params;
    try {
      const { data } = await this.$axios.get(route || '/media/comments', { params });

      commit('setComments', data.comments);

      return data.comments;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};
