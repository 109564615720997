<template>
  <div class="document-viewer-footer">
    <TFlex
      row
      class="pa-0 ma-0 document-viewer-footer__content"
    >
      <TCol
        xs="4"
        class="pa-0 ma-0 document-viewer-footer__left-block"
      >
        <TButton
          v-if="canAccredit"
          secondary-action
          class="mr-3"
          icon="docs-check"
          icon-view-box="0 0 14 10"
          icon-height="10"
          icon-width="14"
          @click="$emit('approve')"
        >
          {{$t('settings.Approve')}}
        </TButton>
        <TButton
          v-if="canAccredit"
          secondary-action
          icon="close"
          icon-fill="#CB1939"
          icon-stroke="none"
          icon-width="11"
          icon-height="11"
          icon-view-box="0 0 11 11"
          @click="$emit('decline')"
        >
          {{$t('settings.Reject')}}
        </TButton>
      </TCol>
      <TCol
        xs="8"
        class="pa-0 ma-0 document-viewer-footer__right-block"
      >
        <TButton
          class="mr-3"
          icon="download-colorless"
          icon-fill="#FFF"
          icon-stroke="#FFF"
          icon-width="13"
          icon-height="13"
          icon-view-box="0 0 13 13"
          @click="$emit('download')"
        >
          {{$t('Download')}}
        </TButton>
        <TButton
          v-if="canDelete"
          :disabled="disabledDelete"
          secondary-action
          icon="delete-colorless"
          icon-stroke="#233239"
          icon-width="13"
          icon-height="13"
          icon-view-box="0 0 13 15"
          @click="$emit('delete')"
        >
          {{$t('Delete')}}
        </TButton>
      </TCol>
    </TFlex>
  </div>
</template>

<script>
export default {
  name: 'DocumentViewerFooter',

  props: {
    canAccredit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    disabledDelete: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/variables';

.document-viewer-footer {
  border-top: 1px solid $light-grey;
  height: 56px;
  display: flex;
  justify-content: space-between;

  padding: 12px 25px;

  &__content {
    flex: 1;
  }

  &__right-block {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
