import model from '~/assets/js/model';

const toastMessageModel = model({
  html: '',
  timeout: undefined, // seconds
  withTimer: undefined,
  height: undefined,
});

export const state = () => ({
  token: null,
  errorMessageToShow: '',
  isMenuShrunk: false,
  isNdsPrice: false,
  isNeedSmsConfirmed: false,
  smsConfirmedUrl: '/',
  smsConfirmedData: {},
  isRedirectEnabled: true,
  isFileLoading: false,
  pageScrollPosition: { x: 0, y: 0 },
  toastMessage: null,
});

export const getters = {
  GET_TOKEN: state => state.token,
  GET_ERROR_MSG: state => state.errorMessageToShow,
  IS_MENU_SHRUNK: state => state.isMenuShrunk,
  isNdsPrice: state => state.isNdsPrice,
  IS_NEED_SMS_CONFIRMED: state => state.isNeedSmsConfirmed,
  GET_SMS_CONFIRMED_DATA: state => state.smsConfirmedData,
  GET_SMS_CONFIRMED_URL: state => state.smsConfirmedUrl,
  GET_IS_REDIRECT_ENABLED: state => state.isRedirectEnabled,
  getIsFileLoading: state => state.isFileLoading,
  getPageScrollPosition: state => state.pageScrollPosition,
  getToastMessage: state => state.toastMessage,
};

export const actions = {
  SET_AUTH_COOKIE({ commit }, token) {
    this.$cookies.set('token', token, { maxAge: 30 * 24 * 3600, path: '/' });

    commit('setToken', token);
  },

  REMOVE_AUTH_COOKIE({ commit }) {
    this.$cookies.remove('token');

    commit('setToken', null);
  },

  SET_NEED_SMS_CONFIRMED({ commit }, value) {
    commit('setSmsConfirmed', value);
  },

  SET_SMS_CONFIRMED_DATA({ commit }, value) {
    commit('setSmsConfirmedData', value);
  },

  SET_SMS_CONFIRMED_URL({ commit }, value) {
    commit('setSmsConfirmedUrl', value);
  },

  SET_AUTH_HEADER(_, token) {
    this.$axios.setAuthHeader(token);
  },

  REMOVE_AUTH_HEADER() {
    this.$axios.setAuthHeader(null);
  },

  TOGGLE_MENU_SHRUNK({ commit }, value) {
    commit('setMenuShrunk', value);
  },

  SET_NDS_VALUE({ commit }, value) {
    this.$cookies.set('nds', value);
    commit('setNdsValue', value);
  },

  SET_IS_REDIRECT_ENABLED({ commit }, value) {
    commit('setIsRedirectEnabled', value);
  },
};

export const mutations = {
  setToken(state, payload) {
    state.token = payload;
  },

  setErrorMessage(state, payload) {
    state.errorMessageToShow = payload;
  },

  setMenuShrunk(state, payload) {
    state.isMenuShrunk = payload;
  },

  setNdsValue(state, payload) {
    state.isNdsPrice = payload;
  },

  setSmsConfirmed(state, payload) {
    state.isNeedSmsConfirmed = payload;
  },

  setSmsConfirmedData(state, payload) {
    state.smsConfirmedData = payload;
  },

  setSmsConfirmedUrl(state, payload) {
    state.smsConfirmedUrl = payload;
  },

  setIsRedirectEnabled(state, payload) {
    state.isRedirectEnabled = payload;
  },

  setIsFileLoading(state, payload) {
    state.isFileLoading = payload;
  },

  setPageScrollPosition(state, payload) {
    state.pageScrollPosition = payload;
  },

  showToastMessage(state, payload) {
    if (typeof payload === 'string') {
      state.toastMessage = toastMessageModel({ html: payload });
    } else {
      state.toastMessage = toastMessageModel(payload);
    }
  },

  hideToastMessage(state) {
    state.toastMessage = null;
  },
};
