<template>
  <div
    v-if="isVisible"
    class="document-viewer"
    :class="{
      'document-viewer--shrank': isMenuShrank,
    }"
  >
    <DocumentViewerHeader
      :label="label"
      :target="target"
      @close="close"
    />
    <TFlex
      row
      class="pa-0 ma-0  document-viewer__content"
    >
      <TCol
        xs="4"
        class="ma-0 pa-0"
      >
        <DocumentViewerInfo
          :document-fields="documentFields"
          :upload-date="uploadDate"
          :short-name="shortName"
          :role="role"
          :comments="comments"
        />
      </TCol>
      <TCol
        xs="8"
        class="ma-0 pa-0"
      >
        <DocumentViewerSlider
          :documents="documents"
          :initial-slide-id="initialSlideId"
          @after-slide="afterSlide"
        />
      </TCol>
    </TFlex>
    <DocumentViewerFooter
      :can-accredit="canAccredit && canUpdate"
      :can-delete="canDelete"
      :disabled-delete="!currentDocument"
      @delete="setDeletedFileId"
      @approve="approveDocuments"
      @decline="declineDocuments"
      @download="downloadDocument"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex';

// Components
import DocumentViewerHeader from './components/DocumentViewerHeader';
import DocumentViewerInfo from '~/components/Features/DocumentViewer/components/DocumentViewerInfo';
import DocumentViewerSlider from '~/components/Features/DocumentViewer/components/DocumentViewerSlider';
import DocumentViewerFooter from '~/components/Features/DocumentViewer/components/DocumentViewerFooter';

// mixins
import accessMixin from '~/assets/js/mixins/accessMixin';

export default {
  name: 'DocumentViewer',

  components: {
    DocumentViewerFooter,
    DocumentViewerSlider,
    DocumentViewerInfo,
    DocumentViewerHeader,
  },

  mixins: [
    accessMixin,
  ],

  data: () => ({
    currentDocument: {},
    comments: [],
  }),

  computed: {
    ...mapGetters({
      isVisible: 'imageViewer/isVisible',
      label: 'imageViewer/label',
      isMenuShrank: 'general/IS_MENU_SHRUNK',
      documents: 'imageViewer/files',
      initialSlideId: 'imageViewer/initialSlideId',
      documentFields: 'imageViewer/documentFields',
      target: 'imageViewer/target',
      canUpdate: 'imageViewer/canUpdate',
      canDelete: 'imageViewer/canDelete',
    }),

    uploadDate() {
      return this.currentDocument && this.currentDocument.uploadDate;
    },

    shortName() {
      return this.currentDocument && this.currentDocument.shortName;
    },

    role() {
      return this.currentDocument && this.currentDocument.role;
    },

    canAccredit() {
      return this.isAdmin && this.documents.some(({ canAccredit }) => canAccredit);
    },
  },

  watch: {
    isVisible(isVisible) {
      if (!isVisible) {
        this.currentDocument = {};
        this.comments = [];
      }
    },

    async currentDocument() {
      if (this.currentDocument?.id) {
        this.comments = await this.loadComments({
          ...this.currentDocument,
          uint8Array: undefined,
        });
      }
    },
  },

  methods: {
    ...mapMutations({
      setVisibility: 'imageViewer/setVisibility',
      deleteFile: 'imageViewer/deleteFile',
      approveFiles: 'imageViewer/approveFiles',
      declineFiles: 'imageViewer/declineFiles',
    }),

    ...mapActions({
      loadComments: 'media/loadComments',
    }),

    afterSlide(current) {
      this.currentDocument = current;
    },

    close() {
      this.setVisibility(false);
    },

    approveDocuments() {
      this.approveFiles(this.currentDocument);
    },

    declineDocuments() {
      this.declineFiles(this.currentDocument);
    },

    setDeletedFileId() {
      if (this.currentDocument?.id) {
        this.deleteFile(this.currentDocument.id);
      }
    },

    downloadDocument() {
      if (this.currentDocument) {
        const request = new XMLHttpRequest();
        request.open('GET', this.currentDocument.url, true);
        request.responseType = 'blob';
        request.onload = () => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([request.response]));
          link.setAttribute('download', this.currentDocument.fileName);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
          }, 10);
        };
        request.send();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.document-viewer {
  background-color: #FFF;
  z-index: 111;
  top: 0;
  bottom: 0;
  left: 256px;
  right: 0;
  flex: 1;
  position: fixed;
  transition: 0.2s;

  &--shrank {
    left: 56px;
  }

  &__content {
    height: calc(100vh - 112px);
    overflow: auto;
  }
}
</style>
