<template>
  <AppEntry>
    <div class="auth">
      <nuxt />
    </div>
  </AppEntry>
</template>

<script>
import AppEntry from '~/AppEntry';

export default {
  components: { AppEntry },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

.auth {
  @include fullScreen;
  @include centerFlexXY;
  background-color: $subtle-grey;
}
</style>
