import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import ru, { dict as dictRu } from '~/assets/js/veeValidate/ru';
import en, { dict as dictEn } from '~/assets/js/veeValidate/en';
import { normalizeNumber, transformCommaToDot } from '~/assets/js/utils';

export default ({ app }) => {
  Vue.use(VeeValidate, {
    events: 'change',
    locale: app.$cookies.get('i18n_locale'),
    dictionary: {
      ru: { messages: ru },
      en: { messages: en },
    },
  });
};

Validator.localize('ru', dictRu);
Validator.localize('en', dictEn);

const isValidInn = (value, { innLength1, innLength2 } = {}) => (
  String(value).length === Number(innLength1) || String(value).length === Number(innLength2)
);
const paramNames = ['innLength1', 'innLength2'];

Validator.extend('inn', isValidInn, {
  paramNames,
});

const isValidItem = (value, { items } = { items: [] }) => items.includes(value);

Validator.extend('items', isValidItem, {
  paramNames: ['items'],
});

const isValidTimePeriod = (value, { innLength1 } = {}) => value > innLength1;

Validator.extend('time_period', isValidTimePeriod, {
  paramNames,
});

const isMaxValue = (value, [maxValue]) => normalizeNumber(value) <= normalizeNumber(maxValue);

Validator.extend('max_integer_value', isMaxValue);

const isMinValue = (value, [minValue]) => normalizeNumber(value) >= normalizeNumber(minValue);

Validator.extend('min_integer_value', isMinValue);

const isLoadingRestrictionPassed = (value, [maxValue]) => normalizeNumber(value) <= normalizeNumber(maxValue);

Validator.extend('loading_restriction', isLoadingRestrictionPassed);

const isBetween = (value, { min, max } = {}) => {
  const newVal = transformCommaToDot(value);

  return Number(min) <= newVal && Number(max) >= newVal;
};

Validator.extend('between', isBetween, {
  paramNames: ['min', 'max'],
});
