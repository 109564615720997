export const state = () => ({
  requestData: {},
  request: {},
  requestHistory: [],
  requestBidsList: [],
  requestBid: {},
  requestsBidsLastLoadedParams: null,
});

export const getters = {
  getRequestData: state => state.requestData,
  getHasPreparedRequestData: state => state.hasPreparedRequestData,
  getRequest: state => state.request,
  getRequestHistory: state => state.requestHistory,
  getRequestBidsList: state => state.requestBidsList,
  getRequestBid: state => state.requestBid,
  getRequestsBidsLastLoadedParams: state => state.requestsBidsLastLoadedParams,
};

export const mutations = {
  setRequestData(state, payload) {
    state.requestData = payload;
  },

  setRequest(state, payload) {
    state.request = payload;
  },

  setRequestHistory(state, payload) {
    state.requestHistory = payload;
  },

  setRequestBidsList(state, payload) {
    state.requestBidsList = payload;
  },

  setRequestBid(state, payload) {
    state.requestBid = payload;
  },

  setRequestBidsLastLoadedParams(state, payload) {
    state.requestsBidsLastLoadedParams = payload;
  },
};

export const actions = {
  createRequest({ commit, dispatch }, payload) {
    return this.$axios.post('/proposal-request', {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  updateRequest({ commit, dispatch }, payload) {
    return this.$axios.put(`proposal-request/${payload.id}`, {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  loadRequest({ commit }, id) {
    return this.$axios.get(`proposal-request/${id}`)
      .then(({ data }) => {
        commit('setRequest', data.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadRequestBidsList({ commit }, id) {
    return this.$axios.get(`proposals/${id}/list`)
      .then(({ data }) => {
        commit('setRequestBidsList', data.data);
        commit('setRequestBidsLastLoadedParams', id);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadRequestBid({ commit }, { id, companyId }) {
    return this.$axios.get(`proposals/${id}/${companyId}`)
      .then(({ data }) => {
        commit('setRequestBid', data.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  saveProposal({ commit }, data) {
    return this.$axios.post('proposals', { ...data });
  },

  updateProposal({ commit }, data) {
    return this.$axios.put('proposals', { ...data });
  },

  loadRequestData({ commit }) {
    return this.$axios.get('proposal-request/create')
      .then(({ data }) => {
        commit('setRequestData', data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadHistory({ commit, dispatch }, id) {
    return this.$axios.get(`/proposal-request/${id}/history`)
      .then(({ data }) => {
        commit('setRequestHistory', Object.values(data.history));
      });
  },

  updateRequestStatus({ commit, dispatch }, { id, status, message }) {
    return this.$axios.post(`/proposal-request/${id}/updateStatus`, { status, end_reason: message })
      .then(({ data }) => data.success)
      .catch(error => {
        console.error(error);
      });
  },
};
