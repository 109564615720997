const TRIPS_ONLY_MINE_KEY = 2;

export const state = () => ({
  tripsData: {},
  isTripsTableLoading: false,
  isTripsStatisticsLoading: false,
  tripsStatistics: null,
  query: {},
  orderId: null,
  moduleId: null,
  isReportLoading: false,
  applicationDocuments: [],
});

export const getters = {
  getTripsList: state => state.tripsData.data,
  getTripsPagination: state => state.tripsData.meta,
  getTripsTableLoading: state => state.isTripsTableLoading,
  getTripsStatisticsLoading: state => state.isTripsStatisticsLoading,
  getTripsStatistics: state => state.tripsStatistics,
  getQuery: state => state.query,
  getOrderId: state => state.orderId,
  getModuleId: state => state.moduleId,
  isReportLoading: state => state.isReportLoading,
  getApplicationDocuments: state => state.applicationDocuments,
};

export const mutations = {
  setTripsData(state, payload) {
    state.tripsData = payload;
  },

  setTripsTableLoading(state, payload) {
    state.isTripsTableLoading = payload;
  },

  setTripsStatisticsLoading(state, payload) {
    state.isTripsStatistics = payload;
  },

  setTripsStatistics(state, payload) {
    if (!state.tripsStatistics) {
      state.tripsStatistics = payload;
    }
  },

  setTrip(state, payload) {
    state.tripsData.data[payload.index] = {
      ...state.tripsData.data[payload.index],
      ...payload,
    };
    state.tripsData.data = [...state.tripsData.data];
  },

  tripsStatisticsRequested(state) {
    state.tripsStatistics = null;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setOrderId(state, payload) {
    state.orderId = payload;
  },

  setModuleId(state, payload) {
    state.moduleId = payload;
  },

  setReportLoading(state, payload) {
    state.isReportLoading = payload;
  },

  setApplicationDocuments(state, payload) {
    state.applicationDocuments = payload;
  },
};

export const actions = {
  getTrips({ commit }, params = {}) {
    commit('setTripsTableLoading', true);

    const filters = {
      ...state.query,
      ...params,
      page: Number.isInteger(Number(params)) ? params : params.page,
      transporterId: params.transporterId ? JSON.parse(params.transporterId)?.id : undefined,
      clientId: params.clientId ? JSON.parse(params.clientId)?.id : undefined,
      creator: params.creator?.length ? JSON.parse(params.creator)
        .map(({ id }) => Number(id)) : undefined,
      orderStatuses: params.orderStatuses?.length ? JSON.parse(params.orderStatuses)
        .map(({ id }) => Number(id)) : undefined,
      insurance_status: params.insurance_status?.length ? JSON.parse(params.insurance_status)
        .map(({ id }) => Number(id)) : undefined,
      statuses: params.statuses?.length ? JSON.parse(params.statuses)
        .map(({ id }) => Number(id)) : undefined,
      loadingPlace: params.loadingPlace?.length ? JSON.parse(params.loadingPlace) : undefined,
      unloadingPlace: params.unloadingPlace?.length ? JSON.parse(params.unloadingPlace) : undefined,
      moduleTypes: params.moduleTypes?.length ? JSON.parse(params.moduleTypes)
        .map(({ id }) => Number(id)) : undefined,
    };

    delete filters.moduleId;

    const tripsEndpoint = params.trips === TRIPS_ONLY_MINE_KEY
      ? '/trips/my-trips'
      : '/trips';

    return this.$axios.get(tripsEndpoint, { params: { ...filters } })
      .then(({ data }) => {
        commit('setTripsData', data);
        return data;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        commit('setTripsTableLoading', false);
      });
  },

  async fetchTripsStatistics({ commit }, params = {}) {
    commit('setTripsStatisticsLoading', true);
    commit('tripsStatisticsRequested');

    const filters = {
      ...state.query,
      ...params,
      transporterId: params.transporterId ? params.transporterId?.id : undefined,
      clientId: params.clientId ? params.clientId?.id : undefined,
      hasVAT: params.hasVAT ? params.hasVAT?.id : undefined,
      loadingPlace: params.loadingPlace ? params.loadingPlace : undefined,
      unloadingPlace: params.unloadingPlace ? params.unloadingPlace : undefined,
      creator: params.creator?.length ? JSON.parse(params.creator).map(({ id }) => Number(id)) : undefined,
      orderStatuses: params.orderStatuses?.length ? JSON.parse(params.orderStatuses).map(({ id }) => Number(id)) : undefined,
      insurance_status: params.insurance_status?.length ? JSON.parse(params.insurance_status).map(({ id }) => Number(id)) : undefined,
      statuses: params.statuses?.length ? JSON.parse(params.statuses).map(({ id }) => Number(id)) : undefined,
      moduleTypes: params.moduleTypes?.length ? JSON.parse(params.moduleTypes).map(({ id }) => Number(id)) : undefined,
    };

    try {
      const { data } = await this.$axios.get(
        '/trips/statistics',
        { params: { ...filters } }
      );

      commit('setTripsStatistics', data.statistics);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setTripsStatisticsLoading', false);
    }
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('setQuery', payload);
  },

  saveOrderId({ commit }, payload) {
    commit('setOrderId', payload);
  },

  saveModuleId({ commit }, payload) {
    commit('setModuleId', payload);
  },

  updateTripStatus({ commit, dispatch }, { index, id, status }) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/trips/${id}/update-status`, { status })
        .then(({ data }) => {
          commit('setTrip', { ...data.trip, index });
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  async loadApplicationDocuments({ commit }, { id, loadingDate = '' }) {
    try {
      const { data } = await this.$axios.get(`/orders/${id}/documentsForTrip/${loadingDate}`);
      commit('setApplicationDocuments', data);
    } catch (error) {
      console.log(error);
    }
  },
};
