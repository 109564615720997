<template>
  <div
    v-if="onlyIcon"
    class="comments-icon"
  >
    <TIconSvg
      v-tooltip="commentsCount !== 0 ? tipText : ''"
      name="comments"
      fill="#5E6979"
      view-box="0 0 20 20"
      width="20"
      height="20"
    />
    <div
      v-show="hasNewComment && commentsCount !== 0"
      class="comments-icon__info comments-icon__info--indicator"
    />
    <div
      v-show="!hasNewComment && commentsCount !== 0"
      class="comments-icon__info comments-icon__info--numbers"
    >
      {{commentsCount}}
    </div>
  </div>
  <TDropdown
    v-else
    ref="commentDropdown"
    :value="isCommentDropdownOpened"
    class="comments"
    :is-external-control="true"
    :disable-reverse="disableReverse"
    @get-open-state="$emit('get-open-state', $event), isCommentDropdownOpened = $event"
  >
    <template #title>
      <div
        class="comments-icon"
        @click="toggleCommentDropdown(true)"
      >
        <Transition name="fade">
          <TPreloader
            v-if="isLoading"
            class="comments__preloader"
            :color="'#5E6979'"
            size="20"
          />
        </Transition>
        <TIconSvg
          name="comments"
          view-box="0 0 20 20"
          :fill="isCommentDropdownOpened ? '#9A3336' : '#5E6979'"
          width="20"
          height="20"
        />
        <div
          v-show="isCommentsIndicatorShown"
          class="comments-icon__info comments-icon__info--indicator"
        />
        <div
          v-show="isCommentsCounterShown"
          class="comments-icon__info comments-icon__info--numbers"
        >
          {{shownCommentsCount}}
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <TFlex
          class="comments-title"
          middle-xs
          between-xs
        >
          <p>{{title}}</p>
          <TIconSvg
            name="close"
            view-box="0 0 11 11"
            width="15"
            height="15"
            fill="#979797"
            stroke="none"
            class="comments-title__close"
            @click="toggleCommentDropdown(false)"
          />
        </TFlex>
      </div>
      <div
        v-bar
        class="comments-content__wrapper"
      >
        <div
          ref="commentsBody"
          class="comments-content"
        >
          <div
            v-for="(comment, commentIndex) in comments"
            :key="comment.vueKey"
            class="comments-content__comment"
          >
            <div class="mb-1 comments-content__comment-title">
              <span
                class="comments-content__comment-name"
                v-html="getCommentName(comment)"
              />
              <span class="comments-content__comment-date">{{formatDate(comment.created_at)}}</span>
            </div>
            <div
              v-if="comment.type !== 1"
              class="mb-1 comments-content__comment-action"
              :class="getCommentClass(comment.type)"
            >
              {{getCommentText(comment)}}
            </div>
            <div
              v-if="isLegalCheckApprovalComment[commentIndex]"
              class="comments-content__comment-text"
            />
            <div
              v-else
              class="comments-content__comment-text"
            >
              {{comment.type === cancelType
                ? `${$t('The rejection reason')}: ${comment.comment}`
                : comment.comment}}
            </div>
          </div>
        </div>
        <p
          v-if="!comments.length"
          class="comments-content__no-comments"
        >
          {{$t('There are no comments')}}
        </p>
      </div>
      <div
        v-if="!isView"
        class="comments-bottom"
      >
        <p class="mb-2 comments-bottom__subtitle">
          {{$t('Enter comment')}}
        </p>
        <TInput
          v-model="comment"
          v-validate="{
            required: true,
            min: [1],
            max: 255,
          }"
          rows="2"
          multiline
          class="mb-2 comments-bottom__input"
          name="comment_field"
          is-auto-expanded
          :error-messages="errors.collect('comment_field')"
          @input="removeErrors(['comment_field'])"
        />
        <TFlex
          class="comments-bottom__actions"
          middle-xs
          :between-xs="isAdmin"
          :end-xs="!isAdmin"
        >
          <TFlex
            v-if="isAdmin"
            class="comments-bottom__actions-checkbox"
            middle-xs
            between-xs
          >
            <TCheckbox
              v-model="isOnlyAdmin"
              class="mr-2"
            />
            <span>{{$t('Only visible to administrators')}}</span>
          </TFlex>
          <TButton
            class="comments-bottom__actions-button"
            :loading="isCommentSending"
            @click.stop="send"
          >
            {{$t('Send')}}
          </TButton>
        </TFlex>
      </div>
    </template>
  </TDropdown>
</template>

<script>
// libs
import moment from 'moment';

// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';

// Mixins
import formMixin from '~/assets/js/mixins/formMixin';

const COMMENT_TYPE = 1;
const APPROVE_TYPE = 2;
const CANCEL_TYPE = 3;

export default {
  name: 'DocumentComments',

  mixins: [formMixin],

  props: {
    type: {
      type: String,
      default: 'docs',
    },

    fetchRoute: {
      type: String,
      default: '',
    },

    saveRoute: {
      type: String,
      default: '',
    },

    creatorName: {
      type: Boolean,
      default: false,
    },

    modelId: {
      type: Number,
      default: null,
    },

    mediaCollectionName: {
      type: String,
      default: '',
    },

    modelName: {
      type: String,
      default: '',
    },

    index: {
      type: Number,
      default: null,
    },

    title: {
      type: String,
      default: '',
    },

    hasNewComment: {
      type: Boolean,
      default: false,
    },

    commentsCount: {
      type: Number,
      default: null,
    },

    isAdmin: {
      type: Boolean,
      default: false,
    },

    isView: {
      type: Boolean,
      default: false,
    },

    onlyIcon: {
      type: Boolean,
      default: false,
    },

    tipText: {
      type: String,
      default: '',
    },

    disableReverse: {
      type: Boolean,
      default: false,
    },

    additionalRequestPayload: {
      type: Object,
      default: () => ({}),
    },

    params: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isCommentDropdownOpened: false,
    isOnlyAdmin: false,
    comment: '',
    isCommentsLoaded: false,
    isLoading: false,
    isCommentSending: false,
    postedCommentsCount: 0,
  }),

  computed: {
    ...mapGetters({
      comments: 'media/getComments',
    }),

    cancelType() {
      return CANCEL_TYPE;
    },

    fileProps() {
      return {
        modelId: this.modelId,
        mediaCollectionName: this.mediaCollectionName,
        modelName: this.modelName,
        index: this.index + 1,
        isOnlyAdmin: 0,
      };
    },

    isCommentsIndicatorShown() {
      return this.hasNewComment && this.commentsCount !== 0 && !this.isCommentsLoaded;
    },

    isCommentsCounterShown() {
      return (!this.hasNewComment || this.postedCommentsCount || this.isCommentsLoaded)
        && this.shownCommentsCount !== 0;
    },

    shownCommentsCount() {
      return Number(this.commentsCount) + this.postedCommentsCount;
    },

    isLegalCheckApprovalComment() {
      return this.comments.map(comment => comment.type === APPROVE_TYPE
      && comment.collection_name === 'legal_check_comments');
    },
  },

  methods: {
    ...mapActions({
      sendComment: 'media/sendComment',
      loadComments: 'media/loadComments',
    }),

    ...mapMutations({
      setCommentStatus: 'drivers/setCommentStatus',
    }),

    moment,

    async toggleCommentDropdown(value) {
      if (this.isCommentDropdownOpened) {
        this.isCommentDropdownOpened = false;
        return;
      }

      if (value) {
        this.isLoading = true;
        await this.loadComments({ ...this.fileProps, route: this.fetchRoute })
          .then(() => {
            this.$refs.commentDropdown.isDropdownShown = value;
            this.isCommentsLoaded = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$refs.commentDropdown.isDropdownShown = value;
      }

      try {
        this.setCommentStatus(this.modelId);
      } catch (err) {
        console.log(err);
      }

      this.toNewCommentsScroll();
    },

    async send() {
      const isValid = await this.validateField('comment_field');

      if (!isValid) {
        this.scrollToFirstError();
        return;
      }

      this.isCommentSending = true;

      await this.sendComment({
        ...this.fileProps,
        ...this.additionalRequestPayload,
        comment: this.comment,
        isOnlyAdmin: this.isOnlyAdmin,
        type: COMMENT_TYPE,
        route: this.saveRoute,
      })
        .then(() => {
          this.comment = null;
          this.postedCommentsCount += 1;
          return this.loadComments({ ...this.fileProps, route: this.fetchRoute });
        })
        .then(() => {
          if (this.isCommentDropdownOpened) {
            this.toNewCommentsScroll(true);
          }
        })
        .finally(() => {
          this.isCommentSending = false;
          this.isOnlyAdmin = false;
        });
    },

    toNewCommentsScroll(isAnimated = false) {
      const { scrollHeight, offsetHeight } = this.$refs.commentsBody || {
        scrollHeight: 0,
        offsetHeight: 0,
      };

      let timeout;
      const scrollTimer = targetScrollOffset => {
        clearTimeout(timeout);
        const scrollStep = 20;
        const scrollInterval = 20;

        timeout = setTimeout(() => {
          if (
            Math.abs(targetScrollOffset) < Math.abs(this.$refs.commentsBody.scrollTop) + scrollStep
          ) {
            this.$refs.commentsBody.scrollTop = targetScrollOffset;
            clearTimeout(timeout);
          } else {
            this.$refs.commentsBody.scrollTop += Math.sign(targetScrollOffset) * scrollStep;
            scrollTimer(targetScrollOffset);
          }
        }, scrollInterval);
      };

      if (isAnimated) {
        scrollTimer(scrollHeight - offsetHeight);
      } else {
        this.$refs.commentsBody.scrollTop = (scrollHeight - offsetHeight);
      }
    },

    formatDate(date) {
      return this.moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm');
    },

    getCommentClass(type) {
      switch (type) {
        case 2:
          return 'approve';

        case 3:
          return 'cancel';

        case 4:
          return 'accredited';

        default:
          return '';
      }
    },

    getCommentName(comment) {
      const { creator } = comment;

      if (!creator) return '';

      const isAdmin = creator.roles[0]?.id === 2 || false;

      if (isAdmin) {
        return this.$t('Administrator');
      }

      return `${comment.creator_company?.name || creator.company?.name || ''} <br/> ${this.creatorName ? creator.profile.shortName : ''}`;
    },

    getCommentSubject(subject) {
      const $t = this.$t.bind(this);

      switch (subject) {
        case 'drivers_comments':
          return $t('Driver');

        case 'cars_comments':
          return $t('Transport');

        case 'legal_check_comments':
          return $t('contractors.Additional legal check');

        default:
          return $t('Document');
      }
    },

    getCommentText(comment) {
      if (comment.type === 2) {
        if (comment.collection_name === 'company_comments') {
          return this.$t('contractors.Company approved');
        }

        if (comment.collection_name === 'legal_check_comments') {
          return `${this.getCommentSubject(comment.collection_name)} ${this.$t('contractors.passed')}`;
        }

        return `${this.getCommentSubject(comment.collection_name)} ${this.$t('contractors.approved')}`;
      }

      if (comment.type === 3) {
        if (comment.collection_name === 'company_comments') {
          return this.$t('contractors.Company rejected');
        }

        if (comment.collection_name === 'legal_check_comments') {
          return `${this.getCommentSubject(comment.collection_name)} ${this.$t('contractors.rejected')}`;
        }

        return `${this.getCommentSubject(comment.collection_name)} ${this.$t('Rejected').toLowerCase()}`;
      }

      if (comment.type === 4) {
        return this.$t('contractors.The company is accredited');
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

  .comments {
    &__preloader {
      position: absolute!important;
      z-index: 1;
      top: 0;
      left: 0;
    }

    &-title {
      background-color: $white-grey;
      height: 58px;
      padding: 0 20px 0 32px;
      border-bottom: 1px solid $light-grey;

      p {
        line-height: 20px;
        font-weight: 600;
      }

      &__close {
        cursor: pointer;
      }
    }

    &-icon {
      position: relative;
      height: 20px;
      cursor: pointer;

      svg {
        outline: none;
      }

      &__info {
        position: absolute;

        &--numbers {
          top: -7px;
          right: 0;
          font-size: 12px;
          line-height: 15px;
          background-color: #fff;
        }

        &--indicator {
          top: -3px;
          right: -2px;
          width: 8px;
          height: 8px;
          border-radius: 5px;
          border: 1px solid #fff;
          background-color: $red;
        }
      }
    }

    &-content {
      max-height: 284px;

      &__wrapper {
        height: 100%;
      }

      &__no-comments {
        padding: 15px 15px 15px 32px;
      }

      &__comment {
        border-top: 1px solid $light-grey;
        padding: 15px 15px 15px 32px;

        &-action {
          &.cancel {
            color: $red;
          }

          &.approve {
            color: $green;
          }

          &.accredited {
            color: $dark-blue;
          }
        }

        &-name {
          line-height: 20px;
          font-weight: 600;
        }

        &-date {
          line-height: 20px;
          font-size: 12px;
          margin-left: 8px;
        }

        &-title {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &-bottom {
      background-color: $white-grey;
      padding: 12px 32px 20px;
      border-top: 1px solid $light-grey;

      &__subtitle {
        line-height: 20px;
      }

      &__input {
        margin-bottom: 12px;

        .t-field__field {
          max-height: 90px;
        }
      }
    }

    .t-dropdown__content-wrapper {
      width: 450px;
      padding: 0px;
      border: 1px solid $light-grey;
    }
  }

  .form-header .t-dropdown {
    position: static;
  }

  .form-header .t-dropdown__content-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }

  .order-info .form-header .t-dropdown__content-wrapper {
    right: 0;
    left: auto;
    transform: none;
  }
</style>
