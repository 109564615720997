export const state = () => ({
  auctionData: {},
  auction: {},
  ordersErrors: [],
  auctionHistory: [],
});

export const getters = {
  getAuctionData: state => state.auctionData,
  getAuction: state => state.auction,
  getOrdersErrors: state => state.ordersErrors,
  getAuctionHistory: state => state.auctionHistory,
};

export const mutations = {
  setAuctionData(state, payload) {
    state.auctionData = payload;
  },

  setAuction(state, payload) {
    state.auction = payload;
  },

  setOrdersErrors(state, payload) {
    state.ordersErrors = payload;
  },

  clearOrdersErrors(state) {
    state.ordersErrors = [];
  },

  setAuctionHistory(state, payload) {
    state.auctionHistory = payload;
  },
};

export const actions = {
  createAuction({ commit, dispatch }, payload) {
    return this.$axios.post('auctions', {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  updateAuction({ commit, dispatch }, payload) {
    return this.$axios.put(`auctions/${payload.id}`, {
      ...payload,
    })
      .then(({ data }) => data.success);
    // Для теста ошибки
    // .catch(error => {
    //   console.error(error);
    // });
  },

  loadAuction({ commit, dispatch }, id) {
    return this.$axios.get(`/auctions/${id}`)
      .then(({ data }) => {
        commit('setAuction', data.auction);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadAuctionData({ commit, dispatch }) {
    return this.$axios.get('auctions/create')
      .then(({ data }) => {
        commit('setAuctionData', data.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadHistory({ commit, dispatch }, id) {
    return this.$axios.get(`/auctions/${id}/history`)
      .then(({ data }) => {
        commit('setAuctionHistory', Object.values(data.history));
      });
  },

  cancelAuction({ commit, dispatch }, { id, message }) {
    return this.$axios.put(`auctions/${id}/cancel`, { end_reason: message })
      .then(({ data }) => data.success);
  },

  finishAuction({ commit, dispatch }, { id, message }) {
    return this.$axios.put(`auctions/${id}/finish`, { end_reason: message })
      .then(({ data }) => data.success)
      .catch(error => {
        console.error(error);
      });
  },
};
