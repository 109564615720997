<template>
  <div>
    <div
      class="t-uploader-inner"
      :style=" disabled ? 'z-index: 80' : 'z-index: 0;' "
      @dragover.prevent="$emit('dragover', $event)"
      @dragleave="$emit('dragleave', $event)"
      @drop.stop.prevent="dropFiles"
      @click="openFileLoader"
    >
      <input
        ref="file"
        :accept="getFileTypes(fileType)"
        :value="inputValue"
        :multiple="isMultiple"
        type="file"
        style="display: none"
        @input="inputChange"
      >
      <slot :file="processedFile" />
      <slot
        name="multiple"
        :files="processedFiles"
      />
    </div>
    <TPopup
      v-if="isWarningPopupShown"
      @close="setIsWarningPopupShown(false)"
    >
      <p>
        {{$t(
          'The maximum size allowed for the uploaded file has been exceeded — {maxSize} Mb',
          { maxSize }
        )}}
      </p>
    </TPopup>
  </div>
</template>

<script>
/* eslint no-param-reassign: 0 */
import { v4 as uuid } from 'uuid';
import isObject from 'lodash/isObject';

import { mapMutations } from 'vuex';

const BYTES_IN_MEGABYTE = 1024 * 1024;

export default {
  name: 'TUploader',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    isDnd: {
      type: Boolean,
      default: false,
    },

    route: {
      type: String,
      required: true,
    },

    method: {
      type: String,
      required: true,
    },

    headers: {
      type: Object,
      default: () => ({}),
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    isAutoUpload: {
      type: Boolean,
      default: true,
    },

    fieldName: {
      type: String,
      default: 'file',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    addData: {
      type: Object,
      default: () => ({}),
    },

    hasPreview: {
      type: Boolean,
      default: false,
    },

    fileType: {
      type: String,
      default: 'any',
    },

    // В MB
    maxSize: {
      type: Number,
      default: 50,
    },
  },

  data: () => ({
    files: [],
    workingThreads: 0,
    uploadSize: 0,
    uploadedSize: 0,
    inputValue: '',
    isWarningPopupShown: false,
  }),

  computed: {
    processedFiles() {
      return [...this.files, ...this.uploadedFiles, {}];
    },

    processedFile() {
      return this.processedFiles[0];
    },

    canUpload() {
      return this.isUploading !== true;
    },

    canAddFiles() {
      return ((!this.isUploading
               && (!this.files.length && !this.value.length))
              || this.isMultiple) && !this.disabled;
    },

    isUploading() {
      return this.workingThreads > 0;
    },

    uploadedFiles() {
      return this.processFiles(this.value, { isQueued: false, progress: 100, uploaded: true });
    },
  },

  watch: {
    isUploading(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.$emit('start');
      } else if (oldVal === true && newVal === false) {
        this.$emit('finish');
      }
    },
  },

  mounted() {
    window.addEventListener('drop', this.handleDocumentDrop, false);
    window.addEventListener('dragover', this.handleDocumentDrop, false);
  },

  beforeDestroy() {
    this.abort();
    window.removeEventListener('drop', this.handleDocumentDrop, false);
    window.removeEventListener('dragover', this.handleDocumentDrop, false);
  },

  methods: {
    ...mapMutations({
      setIsFileLoading: 'general/setIsFileLoading',
    }),

    handleDocumentDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },

    openFileLoader() {
      if (this.canAddFiles) {
        this.$refs.file.click();
      }

      this.$emit('click');
    },

    abort() {
      this.files.forEach(file => {
        if (typeof file.abort === 'function') {
          file.abort();
        }
      });
    },

    processFiles(files, options = {}) {
      if (!this.isMultiple && files.length > 1) {
        return [{
          id: files[0].id || uuid(),
          data: files[0],
          isQueued: true,
          total: files[0].size,
          loaded: 0,
          progress: 0,
          uploaded: false,
          ...options,
        }];
      }

      return files.map(file => ({
        id: file.id || uuid(),
        data: file,
        isQueued: true,
        total: file.size,
        loaded: 0,
        progress: 0,
        uploaded: false,
        ...options,
      }));
    },

    dropFiles(e) {
      this.$emit('drop', e);
      if (this.canAddFiles) {
        this.addFiles(Array.from(e.dataTransfer.files));
      }
    },

    inputChange(e) {
      if (this.canAddFiles) {
        this.addFiles(Array.from(e.target.files));
        this.inputValue = '';
      }
    },

    addFiles(fileList) {
      if (fileList && this.canAddFiles) {
        const isAllowedFiles = this.validateFiles(fileList);

        if (!isAllowedFiles) {
          const message = `
            ${this.$t('Invalid file type')}
            ${this.$t('Allowed')} (${this.getFileTypes(this.fileType)})
          `;
          this.$emit('file-not-allowed', message);

          return;
        }

        this.files = [
          ...this.files,
          ...this.processFiles(fileList),
        ];

        if (this.isAutoUpload) {
          this.upload();
        }
      }
    },

    validateFiles(fileList) {
      let isAllowedFiles = true;

      fileList.forEach(file => {
        const extension = file.name.substring(file.name.lastIndexOf('.'));
        if (!this.getFileTypes(this.fileType).includes(extension)) {
          isAllowedFiles = false;
        }
      });

      return isAllowedFiles;
    },

    removeFile(fileId) {
      const fileIndex = this.files.findIndex(file => file.id === fileId);

      this.files.splice(fileIndex, 1);
    },

    async upload() {
      if (!this.canUpload) {
        return;
      }

      const files = this.files.map(item => ({ ...item }));
      // Отправка файлов по очереди, а не N Запросов одновременно
      /* eslint-disable no-await-in-loop */
      /* eslint-disable no-restricted-syntax */
      for (const file of files) {
        if (file.isQueued) {
          file.isQueued = false;
          await this.uploadFile(file);
        }
      }
      /* eslint-enable no-await-in-loop */
      /* eslint-enable no-restricted-syntax */

      // Синхронная отправка файлов

      // this.files.forEach((file, index) => {
      //   file.isQueued = false;
      //   this.uploadFile(file);
      // });
    },

    async uploadFile(file) {
      this.workingThreads += 1;

      const form = new FormData();

      // Ограничение по размеру файла
      if (this.maxSize && file.data.size >= (this.maxSize * BYTES_IN_MEGABYTE)) {
        this.setIsWarningPopupShown(true);
        return;
      }

      form.append(this.fieldName, file.data, file.data.name);

      const { CancelToken } = this.$axios;
      const headers = {};

      Object.assign(headers, {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json, text/plain, */*',
        'Access-Control-Allow-Origin': '*',
      });

      if (this.hasPreview) {
        Object.keys(this.addData).forEach(key => {
          if (isObject(this.addData[key])) {
            form.append(key, JSON.stringify(this.addData[key]));
          } else {
            form.append(key, this.addData[key]);
          }
        });
      }

      this.setIsFileLoading(true);
      await this.$axios({
        method: this.method,
        url: this.route,
        data: form,
        headers,
        onUploadProgress: e => {
          const processedFile = this.processedFiles.find(({ id }) => id === file.id);

          if (processedFile) {
            processedFile.total = e.total;
            processedFile.loaded = e.loaded;
            processedFile.progress = 100 * e.loaded / e.total;
          }

          if (e.loaded >= e.total) {
            this.workingThreads -= 1;
          }
        },
        cancelToken: new CancelToken(abort => {
          file.abort = remove => {
            if (remove) {
              this.removeFile(file.id);
            }
            abort();
          };
        }),
      }).then(response => {
        this.$emit('response', {
          response,
          file: {
            ...file,
            data: {
              name: file.data.name,
            },
          },
        });
        this.removeFile(file.id);
      }).catch(error => {
        this.workingThreads -= 1;
        this.$emit('error', error);
      }).finally(() => {
        this.setIsFileLoading(false);
      });
    },

    getFileTypes(type) {
      switch (type) {
        case 'imageOrDoc': {
          return '.pdf,.jpg,.jpeg,.png,.bmp,.doc,.docx,.xls,.xlsx';
        }
        case 'imageOrPdf': {
          return '.pdf,.jpg,.jpeg,.png,.bmp';
        }
        default: {
          return '.pdf,.txt,.jpg,.jpeg,.png,.doc,.xls,.xlsx';
        }
      }
    },

    setIsWarningPopupShown(isOpen) {
      this.isWarningPopupShown = isOpen;
    },
  },
};
</script>
