<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    class="t-multiselect-new"
    :rules="validationRules"
  >
    <TMultiselect
      v-model="localValue"
      v-bind="{...$props, ...$attrs}"
      :error-messages="errors"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TMultiselectProvider',

  components: {
    Multiselect,
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },

    validationRules: {
      type: [String, Object],
      default: null,
    },
  },

  data: () => ({
    localValue: null,
  }),

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    unselectTag(index) {
      if (this.multiple) {
        const localValue = this.value.slice();

        localValue.splice(index, 1);
        this.$emit('input', localValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-multiselect-new {
  width: 100%;
}
</style>
