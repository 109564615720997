<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TTab',

  props: {
    label: {
      type: String,
      required: true,
    },

    slug: {
      type: String,
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },

    numbersCount: {
      type: Number,
      default: null,
    },

    approvedDocsCount: {
      type: Number,
      default: null,
    },

    approvedCarsCount: {
      type: Number,
      default: null,
    },

    approvedDriversCount: {
      type: Number,
      default: null,
    },

    canceledDocsCount: {
      type: Number,
      default: null,
    },

    canceledCarsCount: {
      type: Number,
      default: null,
    },

    canceledDriversCount: {
      type: Number,
      default: null,
    },

    driversCommentsUnread: {
      type: Boolean,
      default: false,
    },

    carsCommentsUnread: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isActive: false,
  }),

  created() {
    this.isActive = this.active;
    this.$on('change-active', isActive => {
      this.isActive = isActive;
    });
  },
};
</script>
