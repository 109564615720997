<template>
  <component
    :is="isNuxtLink ? 'nuxt-link' : 'a'"
    :to="isNuxtLink ? route : null"
    :href="route"
    :target="target"
    class="t-link"
    :class="{
      't-link--underlined': isUnderlined,
      't-link--disabled': isDisabled,
      't-link--has-icon': hasIcon,
      't-link--multiline': isMultiline}"
    @click="$emit('click', $event)"
  >
    <TIconSvg
      v-if="hasIcon"
      :name="iconName"
      :fill="iconFill"
      :stroke="iconStroke"
      :width="iconWidth"
      :height="iconHeight"
      :view-box="iconViewBox"
      class="t-link__icon"
    />
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TLink',

  props: {
    isNuxtLink: {
      type: Boolean,
      default: true,
    },

    route: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isUnderlined: {
      type: Boolean,
      default: true,
    },

    isMultiline: {
      type: Boolean,
      default: false,
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },

    iconName: {
      type: String,
      default: 'link-arrow',
    },

    iconWidth: {
      type: [String, Number],
      default: 8,
    },

    iconHeight: {
      type: [String, Number],
      default: 12,
    },

    iconViewBox: {
      type: String,
      default: '0 0 6 10',
    },

    iconFill: {
      type: String,
      default: 'currentColor',
    },

    iconStroke: {
      type: String,
      default: 'currentColor',
    },

    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-link {
  position: relative;
  color: $light-blue;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: color 0.3s, opacity 0.3s;

  &:hover:not(.t-link--underlined) {
    color: darken($light-blue, 15%);
  }

  &:active:not(.t-link--disabled) {
    color: rgba($light-blue, 0.7);
  }

  &--disabled {
    cursor: default;
    color: $steel-grey;
  }

  &--multiline {
    white-space: normal;
  }

  &--underlined {
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      background-color: $light-blue;
      transition: background-color 0.3s;
    }

    &:hover::after {
      background-color: transparent;
    }

    &.t-link--multiline {
      &::after {
        display: none;
      }

      text-decoration: underline;
      text-decoration-color: $light-blue;
      transition: text-decoration-color 0.3s;

      &:hover {
        text-decoration-color: transparent;
      }
    }
  }

  &--has-icon {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    margin-right: 10px;
  }
}
</style>
