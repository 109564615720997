// Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { createNamespacedHelpers } from 'vuex';

// Vuex

const { mapState, mapGetters } = createNamespacedHelpers('main');

export default {
  computed: {
    ...mapState({
      currentUser: store => store.currentUser,
      menuSections: store => store.menuSections,
      permissions: store => store.permissions,
      access: store => store.access,
      currentAccess: store => store.currentAccess,
    }),

    ...mapGetters([
      'isAdmin',
      'isClient',
      'isForwarder',
      'isClientOrForwarder',
      'isTransporter',
      'isTransporterOrForwarder',
    ]),

    isEditUser() {
      return this.getEdit('users');
    },

    isViewUser() {
      return this.getView('users');
    },

    isEditCars() {
      return (this.isAdmin && this.getEdit('cars'))
        || (this.getEdit('cars') && this.isTransporter)
        || (this.getEdit('cars') && this.isClientOrForwarder);
    },

    isViewCars() {
      return (this.isAdmin && this.getView('cars'))
        || (this.getView('cars') && this.isTransporter)
        || (this.getView('cars') && this.isClientOrForwarder);
    },

    isEditDrivers() {
      return (this.isAdmin && this.getEdit('drivers'))
        || (this.getEdit('drivers') && this.isTransporter)
        || (this.getEdit('drivers') && this.isClientOrForwarder);
    },

    isViewDrivers() {
      return (this.isAdmin && this.getView('drivers'))
        || (this.getView('drivers') && this.isTransporter)
        || (this.getView('drivers') && this.isClientOrForwarder);
    },

    isEditTransporter() {
      return !this.isTransporter && this.getEdit('transporters');
    },

    isViewTransporter() {
      return !this.isTransporter && this.getView('transporters');
    },

    isEditOrders() {
      return !this.isTransporter && (this.getEdit('orders'));
    },

    isViewOrders() {
      return this.getView('orders');
    },

    isViewProfile() {
      return !this.isAdmin && this.getView('profile');
    },

    isEditProfile() {
      return !this.isAdmin && this.getEdit('profile');
    },

    isViewBranches() {
      return this.getView('branches');
    },

    isEditBranches() {
      return this.getEdit('branches');
    },

    isViewForwarders() {
      return this.getView('client_forwarders');
    },

    isEditForwarders() {
      return this.getEdit('client_forwarders');
    },

    isViewTrips() {
      return this.getView('trips');
    },

    isViewTariffScales() {
      return this.getView('references');
    },

    isEditTrips() {
      return (this.isAdmin && this.getEdit('trips'))
        || (this.isTransporter && this.getEdit('trips'))
        || (this.isClientOrForwarder && this.getEdit('trips'));
    },

    isEditDocs() {
      return (this.isAdmin || this.isClientOrForwarder);
    },

    isEditAuctions() {
      return this.getEdit('auctions');
    },

    isViewCustomers() {
      return !this.isTransporter && this.getView('customers');
    },

    isEditCustomers() {
      return !this.isTransporter && this.getEdit('customers');
    },

    isViewBidding() {
      return this.getView('auctions');
    },

    isEditBidding() {
      return this.getEdit('auctions');
    },

    isViewSettings() {
      return this.getView('settings') && !this.isTransporter;
    },

    isViewCorrespondence() {
      return this.getView('documents_flow');
    },

    isEditCorrespondence() {
      return this.getEdit('documents_flow');
    },

    isEditSettings() {
      return this.getEdit('settings') && !this.isTransporter;
    },

    isEditTariffScales() {
      return this.getEdit('references');
    },

    isViewRealizations() {
      return this.getView('realizations');
    },

    isEditRealizations() {
      return this.getEdit('realizations');
    },

    isViewStatistics() {
      return this.getView('statistics');
    },

    isEditStatistics() {
      return this.getEdit('statistics');
    },

    isViewTransportersGroups() {
      return this.isClientOrForwarder && this.getView('transporters_groups');
    },

    isEditTransportersGroups() {
      return this.isClientOrForwarder && this.getEdit('transporters_groups');
    },

    isViewDirectoriesTransporters() {
      return this.getView('directories_transporters');
    },

    isEditDirectoriesTransporters() {
      return this.getEdit('directories_transporters');
    },
  },

  methods: {
    getEdit(key) {
      return Boolean(
        this.currentAccess[key]
        && this.currentAccess[key].edit
        && this.currentAccess[key].edit.isCheck
      );
    },

    getView(key) {
      return Boolean(
        this.currentAccess[key]
        && this.currentAccess[key].view
        && this.currentAccess[key].view.isCheck
      );
    },

    forrmattedPermissions(userPermission) {
      return this.permissions.reduce((acc, { id, section, action }) => {
        const currentPerm = userPermission?.find(({ id: currentId }) => currentId === id);
        if (!currentPerm) return acc;
        if (acc[section]) {
          // eslint-disable-next-line no-param-reassign
          acc[section][action] = {
            isCheck: true,
            id: currentPerm.id,
          };
        } else {
          // eslint-disable-next-line no-param-reassign
          acc[section] = {};
          // eslint-disable-next-line no-param-reassign
          acc[section][action] = {
            isCheck: true,
            id: currentPerm.id,
          };
        }
        return acc;
      }, {});
    },
  },
};
