export const state = () => ({
  instructionContent: '',
});

export const getters = {
  getInstructionText: state => state.instructionContent,
};

export const mutations = {
  setInstructionText(state, payload) {
    state.instructionContent = payload;
  },
};

export const actions = {
  async loadInstructionText({ commit }) {
    await this.$axios.get('/instructions/instruction')
      .then(data => {
        commit('setInstructionText', data.data.data.instruction);
      })
      .catch(error => console.log(error));
  },

  async saveInstructionText({ commit }, payload) {
    await this.$axios.post('/settings/instruction/save', { ...payload })
      .then(() => {
        commit('setInstructionText', payload);
      })
      .catch(error => console.log(error));
  },
};
