import { v4 as uuid } from 'uuid';

export const state = () => ({
  docsLabels: {},
  docDefaultNumber: '',
  docsShortLabels: [],
  orderTransporters: [],
  docs: [],
  docsByType: [],
  docsPagination: {},
  query: {},
});

export const getters = {
  GET_DOCS_SHORT_LABELS: state => state.docsShortLabels,
  GET_DOCS_LABELS: state => state.docsLabels,
  GET_DOCS_DEFAULT_NUMBER: state => state.docDefaultNumber,
  GET_ORDER_TRANSPORTERS: state => state.orderTransporters,
  GET_DOCS_DATA: state => state.docs,
  GET_DOCS_PAGINATION: state => state.docsPagination,
  GET_DOCS_BY_TYPE: state => state.docsByType,
};

export const actions = {
  LOAD_DOCS_DATA({ commit, dispatch, state }, { orderId, params }) {
    return this.$axios.get(`/orders/${orderId}/documents`, { params }).then(({ data }) => {
      commit('setDocsData', data.data);
      commit('setPagination', data.meta);
    }).catch(e => console.log(e));
  },

  LOAD_DOCS_LABELS({ commit, dispatch }, payload) {
    return this.$axios.get('/documents/labels')
      .then(({ data }) => {
        commit('setDocsLabels', data);
      }).catch(e => console.log(e));
  },

  LOAD_DOCS_DEFAULT_NUMBER({ commit }, params) {
    return this.$axios.get('/documents/generate-number', { params })
      .then(({ data }) => {
        commit('setDocDefaultNumber', data);
      }).catch(e => console.log(e));
  },

  LOAD_DOCS_BY_TYPE({ commit }, params) {
    return this.$axios.get(`/documents/${params.orderId}`, { params, type: params.type })
      .then(({ data }) => commit('setDocByType', data))
      .catch(e => console.log(e));
  },

  LOAD_ORDER_TRANSPORTERS({ commit, dispatch }, orderId) {
    return this.$axios.get(`orders/${orderId}/transporters`)
      .then(({ data }) => {
        commit('setOrderTransporters', data);
      }).catch(e => console.log(e));
  },

  CREATE_DOC({ commit, dispatch }, payload) {
    return this.$axios.post(`/documents/${payload.route}/`, { ...payload });
  },

  UPDATE_DOC({ commit }, params) {
    return this.$axios.put(`/documents/${params.route}/${params.docId}`, params);
  },

  DECLINE_DOCUMENT({ commit }, params) {
    return this.$axios.put(`/documents/${params.docId}/cancel`, params)
      .catch(err => {
        console.log(err);
      });
  },

  REMOVE_DOCUMENT({ dispatch }, docId) {
    return this.$axios.delete(`/documents/${docId}`)
      .catch(err => {
        console.log(err);
      });
  },
};

export const mutations = {
  setDocsData(state, payload) {
    payload.forEach(doc => delete doc.mediaDocuments.comments);
    state.docs = payload.map(doc => ({
      ...doc,
      mediaDocuments: Object.values(doc.mediaDocuments).flat().map(media => ({
        ...media,
        created_by: media.file.custom_properties.creator_short_name,
        created_at: media.file.created_at,
        id: uuid(),
        link: {
          url: media.url,
          preview: media.preview,
          fullUrl: media.fullUrl,
        },
      })),
    }));
  },

  setScansUploaded(state, payload) {
    state.docs[payload.index].mediaDocuments = payload.docs;
  },

  setPagination(state, payload) {
    state.docsPagination = payload;
  },

  setDocsLabels(state, payload) {
    state.docsShortLabels = Object.entries(payload.labels.types)
      .map(([id, name]) => ({ id: Number(id), name }));
    state.docsLabels = payload.labels;
  },

  setOrderTransporters(state, payload) {
    state.orderTransporters = payload.transporters;
  },

  setDocDefaultNumber(state, payload) {
    state.docDefaultNumber = payload;
  },

  setDocByType(state, payload) {
    state.docsByType = payload;
  },
};
