<template>
  <div class="t-input-search">
    <TInput
      :value="value"
      has-icon
      icon-name="close"
      :icon-height="11"
      :icon-width="11"
      icon-view-box="0 0 11 11"
      icon-fill="#C4C4C4"
      :label="label"
      :placeholder="placeholder"
      @enter="$emit('search', $event)"
      @icon-click="iconClick"
      @input="$emit('input', $event)"
    />
    <TButton
      class="t-input-search__search-btn"
      icon="search"
      icon-view-box="0 0 17 17"
      icon-height="17"
      icon-width="17"
      color="yellow"
      @click="$emit('search', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'TInputSearch',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  methods: {
    iconClick() {
      this.$emit('input', '');
      this.$emit('icon-click');
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-input-search {
  display: flex;
  width: 100%;
  &__clear {
    cursor: pointer;
  }

  .t-field__icon {
    right: 10px;
    cursor: pointer;
  }

  &__search-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-right: 1px;
  }
}
</style>
