export const state = () => ({
  types: [],
  labels: {},
  productStatuses: [],
  productKinds: [],
});

export const getters = {
  getLabels: state => state.labels,
  getProductStatuses: state => state.productStatuses,
  getPproductKinds: state => state.productKinds,
  getProductTypesList: state => state.types,
};

export const mutations = {
  setLabels(state, payload) {
    state.labels = payload;
    state.productStatuses = Object.values(payload.statuses);
    state.productKinds = Object.values(payload.kinds);
  },

  setProductTypesList(state, payload) {
    state.types = payload;
  },
};

export const actions = {
  async loadLabels({ commit }) {
    await this.$axios.get('/product-types/labels')
      .then(({ data }) => {
        commit('setLabels', data.labels.productType);
      })
      .catch(error => console.log(error));
  },

  async loadProductTypes({ commit }) {
    await this.$axios.get('/product-types')
      .then(({ data }) => {
        commit('setProductTypesList', data.data);
      })
      .catch(error => console.log(error));
  },

  async createProductType({ dispatch }, data) {
    await this.$axios.post('/product-types', data);
  },

  async updateProductType({ dispatch }, data) {
    await this.$axios.put(`/product-types/${data.id}`, data);
  },

  async deleteProductType({ dispatch }, id) {
    await this.$axios.delete(`/product-types/${id}`)
      .then(() => {
        dispatch('loadProductTypes');
      });
  },

  async aproveProductType({ dispatch }, id) {
    await this.$axios.get(`/product-types/${id}/approve`)
      .then(() => {
        dispatch('loadProductTypes');
      });
  },

  async createProduct({ dispatch }, data) {
    await this.$axios.post('/products', data);
  },

  async updateProduct({ dispatch }, data) {
    await this.$axios.put(`/products/${data.id}`, data);
  },

  async deleteProduct({ dispatch }, id) {
    await this.$axios.delete(`/products/${id}`)
      .then(() => {
        dispatch('loadProductTypes');
      })
      .catch(err => {
        console.log(err);
      });
  },

  async aproveProduct({ dispatch }, id) {
    await this.$axios.get(`/products/${id}/approve`)
      .then(() => {
        dispatch('loadProductTypes');
      });
  },
};
