<template>
  <div
    class="t-dropdown"
    :class="{'t-dropdown--disabled': disabled}"
  >
    <span ref="dropdown-link">
      <slot name="title" />
    </span>
    <div ref="dropdown-content">
      <Transition name="g-fade">
        <div
          v-if="isDropdownShown"
          ref="dropdown-list"
          :class="{
            't-dropdown__content-wrapper--reverse': !bottom && !disableReverse,
            't-dropdown__content-wrapper--top': top,
            't-dropdown__content-wrapper--right': right
          }"
          class="t-dropdown__content-wrapper"
        >
          <div
            class="t-dropdown__content"
            @click="handleContentClick"
          >
            <slot name="content" />
          </div>
        </div>
      </Transition>
      <div
        v-if="isDropdownShown"
        class="t-dropdown__arrow"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TDropdown',

  props: {
    hover: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },

    isExternalControl: {
      type: Boolean,
      default: false,
    },

    disableReverse: {
      type: Boolean,
      default: false,
    },

    top: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },

    closeOnSelect: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isDropdownShown: false,
    bottom: true,
  }),

  watch: {
    value(value) {
      if (this.isExternalControl) {
        this.isDropdownShown = value;
      }
    },

    isDropdownShown(isShown) {
      if (isShown) {
        this.$nextTick(() => {
          this.checkDirectionDropdown();
        });
        this.$emit('change-dropdown-state', Boolean(isShown));
      }
      this.$emit('get-open-state', Boolean(isShown));
      this.$emit('input', Boolean(isShown));
    },
  },

  created() {
    if (this.isExternalControl) {
      this.isDropdownShown = this.value;
    }
  },

  mounted() {
    const eventName = this.hover ? 'mouseover' : 'click';

    document.addEventListener(eventName, this.toggleDropdown);
    window.addEventListener('resize', this.checkDirectionDropdown);
  },

  beforeDestroy() {
    const eventName = this.hover ? 'mouseover' : 'click';

    window.removeEventListener('resize', this.checkDirectionDropdown);
    document.removeEventListener(eventName, this.toggleDropdown);
  },

  methods: {
    toggleDropdown({ target }) {
      // Если будет баг с дропдауном, нужно проверить это,
      // но тогда сломается дропдавн комментов в доках

      if (this.isExternalControl && !this.isDropdownShown) {
        return;
      }
      const checkOf = element => (typeof element === 'undefined' ? '' : element);
      const [link, content] = [
        checkOf(this.$refs['dropdown-link']),
        checkOf(this.$refs['dropdown-content']),
      ];
      const hasTarget = element => element !== '' && element.contains(target);

      if (hasTarget(content)) {
        return;
      }

      this.isDropdownShown = hasTarget(link) ? !this.isDropdownShown : false;
    },

    checkDirectionDropdown() {
      if (!this.isDropdownShown) {
        return;
      }

      const { innerHeight } = window;
      const { top } = this.$el.getBoundingClientRect();
      this.bottom = top < (innerHeight / 2);
    },

    forceClose() {
      this.isDropdownShown = false;
    },

    handleContentClick() {
      if (this.closeOnSelect) {
        this.toggleDropdown(false);
      }
    },
  },
};
</script>

<style lang="scss">
.t-dropdown {
  position: relative;

  &__content-wrapper {
    position: absolute;
    z-index: 99;
    right: 15px;
    padding: 10px 0;
    border-radius: 3px;
    background-color: #FFF;
    box-shadow: 0 2px 10px rgba(#000, 0.25);

    & .t-button {
      display: flex !important;
      justify-content: flex-start;
    }

    &--reverse {
      top: inherit !important;
      bottom: 100%;
    }

    &--top {
      top: inherit;
      bottom: 100%;
    }

    &--right {
      left: 0;
      right: auto;
    }
  }

  &--disabled{
    pointer-events: none;
    cursor: default;
  }
}
</style>
