<template>
  <div class="t-button-group">
    <div class="t-button-group__content">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="t-button-group__buttons-wrap"
      >
        <TButton
          v-if="!option.isLink"
          :class="{ 't-button-group__button--active': getIsTabActive(option) }"
          :loading="loading"
          class="t-button-group__button"
          transparent
          @click="$emit('input', option.value)"
        >
          {{option.text}}
        </TButton>
        <TLink
          v-else
          :is-nuxt-link="isNuxtLink"
          :is-underlined="false"
          :route="option.link"
          :class="{ 't-button-group__button--active': option.isActive }"
          class="t-button t-button-group__button link"
          transparent
        >
          {{option.text}}
        </TLink>
        <span
          v-tooltip="tooltip ? `${tooltip} ${option.text}` : ''"
          class="t-button-group__hint"
        >
          {{option.hint}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

// Libs
import { isEqual } from 'chober';

export default {
  name: 'TButtonGroup',

  props: {
    options: {
      type: Array,
      required: true,
    },

    isNuxtLink: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: () => '',
    },

    value: {
      type: [String, Number, Boolean, Array],
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isEqual,

    getIsTabActive(option) {
      return option.value === this.value
        || isEqual(option.value, this.value)
        || Array.isArray(option.value) && option.value.includes(this.value[0])
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-button-group {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .t-button#{&}__button {
    display: flex;
    border-radius: 0;
    width: 100%;
    height: $default-control-height;
    color: $steel-grey;
    font-weight: 400;
    border: 1px solid $light-grey;
    border-right-width: 0;
    transition: color 0.2s, border-bottom-color 0.2s, box-shadow 0.2s;

    &.link {
      padding: 0;
    }

    &:hover {
      color: $almost-black;
    }

    &--active {
      border-bottom-color: $brick-red;
      box-shadow: inset 0 -1px 0 0 $brick-red;
      color: $almost-black;
    }
  }

  &__buttons-wrap {
    position: relative;
    flex: 1;

    &:first-child {
      .t-button.t-button-group__button {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }

    &:last-child {
      .t-button.t-button-group__button {
        border-right-width: 1px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  &__hint {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
