<template>
  <div class="t-rating">
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.055 0.966226C11.2407 0.603403 11.7593 0.603403 11.945 0.966226L14.5783 6.10899C14.7962 6.5345 15.2039 6.83068 15.6759 6.9064L21.3807 7.82162C21.7832 7.88619 21.9434 8.37935 21.6557 8.66815L17.5784 12.7618C17.2411 13.1005 17.0854 13.5797 17.1592 14.052L18.0517 19.7604C18.1146 20.1631 17.6951 20.4679 17.3315 20.2836L12.1783 17.6708C11.7519 17.4546 11.2481 17.4546 10.8217 17.6708L5.66846 20.2836C5.3049 20.4679 4.88539 20.1631 4.94835 19.7604L5.84081 14.052C5.91465 13.5797 5.75894 13.1005 5.42158 12.7618L1.34426 8.66815C1.0566 8.37935 1.21684 7.88619 1.61931 7.82162L7.32411 6.9064C7.79612 6.83068 8.20377 6.5345 8.42165 6.10899L11.055 0.966226Z"
        stroke="#FFCC67"
      />
    </svg>
    <div
      class="t-rating__background"
      :style="{ width: `${fillPercent}%` }"
    >
      <svg
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="#FFCC67"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.055 0.966226C11.2407 0.603403 11.7593 0.603403 11.945 0.966226L14.5783 6.10899C14.7962 6.5345 15.2039 6.83068 15.6759 6.9064L21.3807 7.82162C21.7832 7.88619 21.9434 8.37935 21.6557 8.66815L17.5784 12.7618C17.2411 13.1005 17.0854 13.5797 17.1592 14.052L18.0517 19.7604C18.1146 20.1631 17.6951 20.4679 17.3315 20.2836L12.1783 17.6708C11.7519 17.4546 11.2481 17.4546 10.8217 17.6708L5.66846 20.2836C5.3049 20.4679 4.88539 20.1631 4.94835 19.7604L5.84081 14.052C5.91465 13.5797 5.75894 13.1005 5.42158 12.7618L1.34426 8.66815C1.0566 8.37935 1.21684 7.88619 1.61931 7.82162L7.32411 6.9064C7.79612 6.83068 8.20377 6.5345 8.42165 6.10899L11.055 0.966226Z"
          stroke="#FFCC67"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TRating',

  props: {
    rating: {
      type: Number,
      required: true,
    },

    maxRating: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    fillPercent() {
      return (this.rating / this.maxRating) * 100;
    },
  },
};
</script>

<style lang="scss">
.t-rating {
  display: inline-block;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
</style>
