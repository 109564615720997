import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07398a4c = () => interopDefault(import('../pages/bi/index.vue' /* webpackChunkName: "pages/bi/index" */))
const _7d06fb9e = () => interopDefault(import('../pages/bidding/index.vue' /* webpackChunkName: "pages/bidding/index" */))
const _61aaeb3d = () => interopDefault(import('../pages/bidding/index/create.vue' /* webpackChunkName: "pages/bidding/index/create" */))
const _4d0ed78b = () => interopDefault(import('../pages/bidding/index/edit.vue' /* webpackChunkName: "pages/bidding/index/edit" */))
const _f39b3094 = () => interopDefault(import('../pages/bidding/index/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/edit/_id" */))
const _0fa647a2 = () => interopDefault(import('../pages/bidding/index/info.vue' /* webpackChunkName: "pages/bidding/index/info" */))
const _98950b4c = () => interopDefault(import('../pages/bidding/index/info/_id.vue' /* webpackChunkName: "pages/bidding/index/info/_id" */))
const _f9492d1c = () => interopDefault(import('../pages/bidding/index/price-requests/create.vue' /* webpackChunkName: "pages/bidding/index/price-requests/create" */))
const _6c794200 = () => interopDefault(import('../pages/bidding/index/price-requests/edit.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit" */))
const _0487a6aa = () => interopDefault(import('../pages/bidding/index/price-requests/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit/_id" */))
const _74e163a4 = () => interopDefault(import('../pages/bidding/index/price-requests/info.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info" */))
const _2b3f3f4f = () => interopDefault(import('../pages/bidding/index/price-requests/info/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info/_id" */))
const _51b60ffe = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _460e2add = () => interopDefault(import('../pages/companies/index/create.vue' /* webpackChunkName: "pages/companies/index/create" */))
const _5b0501aa = () => interopDefault(import('../pages/companies/index/edit.vue' /* webpackChunkName: "pages/companies/index/edit" */))
const _42778154 = () => interopDefault(import('../pages/companies/index/edit/_id.vue' /* webpackChunkName: "pages/companies/index/edit/_id" */))
const _3dd39fac = () => interopDefault(import('../pages/companies/index/edit/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/edit/_customerId/_clientId" */))
const _7d9b83cf = () => interopDefault(import('../pages/companies/index/info.vue' /* webpackChunkName: "pages/companies/index/info" */))
const _0c4751fa = () => interopDefault(import('../pages/companies/index/info/_id.vue' /* webpackChunkName: "pages/companies/index/info/_id" */))
const _e0413c64 = () => interopDefault(import('../pages/companies/index/info/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/info/_customerId/_clientId" */))
const _2fdd0326 = () => interopDefault(import('../pages/correspondence/index.vue' /* webpackChunkName: "pages/correspondence/index" */))
const _a1851ce4 = () => interopDefault(import('../pages/correspondence/index/create.vue' /* webpackChunkName: "pages/correspondence/index/create" */))
const _6f80be1c = () => interopDefault(import('../pages/correspondence/index/edit.vue' /* webpackChunkName: "pages/correspondence/index/edit" */))
const _8d86c472 = () => interopDefault(import('../pages/correspondence/index/edit/_id.vue' /* webpackChunkName: "pages/correspondence/index/edit/_id" */))
const _0ecfa16e = () => interopDefault(import('../pages/drivers/index.vue' /* webpackChunkName: "pages/drivers/index" */))
const _47c31b6d = () => interopDefault(import('../pages/drivers/index/create.vue' /* webpackChunkName: "pages/drivers/index/create" */))
const _ea55588a = () => interopDefault(import('../pages/drivers/index/edit.vue' /* webpackChunkName: "pages/drivers/index/edit" */))
const _4ef9621c = () => interopDefault(import('../pages/drivers/index/edit/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/edit/_id/_companyId" */))
const _35f3585f = () => interopDefault(import('../pages/drivers/index/info.vue' /* webpackChunkName: "pages/drivers/index/info" */))
const _d2e22464 = () => interopDefault(import('../pages/drivers/index/info/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/info/_id/_companyId" */))
const _654c50b8 = () => interopDefault(import('../pages/instructions/index.vue' /* webpackChunkName: "pages/instructions/index" */))
const _549ea80c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _76deded3 = () => interopDefault(import('../pages/no-access/index.vue' /* webpackChunkName: "pages/no-access/index" */))
const _865ef010 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _2775a5a3 = () => interopDefault(import('../pages/orders/index/create.vue' /* webpackChunkName: "pages/orders/index/create" */))
const _02f0f371 = () => interopDefault(import('../pages/orders/index/edit.vue' /* webpackChunkName: "pages/orders/index/edit" */))
const _0438209c = () => interopDefault(import('../pages/orders/index/edit/_id.vue' /* webpackChunkName: "pages/orders/index/edit/_id" */))
const _a3e20fd6 = () => interopDefault(import('../pages/orders/index/info.vue' /* webpackChunkName: "pages/orders/index/info" */))
const _31bb3340 = () => interopDefault(import('../pages/orders/index/info/_id.vue' /* webpackChunkName: "pages/orders/index/info/_id" */))
const _11817ecc = () => interopDefault(import('../pages/orders/index/info/_id/getTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/getTrip" */))
const _526695c4 = () => interopDefault(import('../pages/orders/index/info/_id/trip.vue' /* webpackChunkName: "pages/orders/index/info/_id/trip" */))
const _fc08b7ee = () => interopDefault(import('../pages/orders/index/info/_id/viewTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/viewTrip" */))
const _5fb705aa = () => interopDefault(import('../pages/password-recovery/index.vue' /* webpackChunkName: "pages/password-recovery/index" */))
const _3b6e604c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _f9a00928 = () => interopDefault(import('../pages/public-orders/index.vue' /* webpackChunkName: "pages/public-orders/index" */))
const _d34ff84a = () => interopDefault(import('../pages/references/index.vue' /* webpackChunkName: "pages/references/index" */))
const _61f31036 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _9c328494 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _61c75bcc = () => interopDefault(import('../pages/transport/index.vue' /* webpackChunkName: "pages/transport/index" */))
const _7f96c962 = () => interopDefault(import('../pages/transport/index/create.vue' /* webpackChunkName: "pages/transport/index/create" */))
const _ffed37c6 = () => interopDefault(import('../pages/transport/index/edit.vue' /* webpackChunkName: "pages/transport/index/edit" */))
const _69105b48 = () => interopDefault(import('../pages/transport/index/edit/_id.vue' /* webpackChunkName: "pages/transport/index/edit/_id" */))
const _2b2768c1 = () => interopDefault(import('../pages/transport/index/info.vue' /* webpackChunkName: "pages/transport/index/info" */))
const _d2d92428 = () => interopDefault(import('../pages/transport/index/info/_id.vue' /* webpackChunkName: "pages/transport/index/info/_id" */))
const _52c02aa0 = () => interopDefault(import('../pages/transporters/index.vue' /* webpackChunkName: "pages/transporters/index" */))
const _5a6a48e7 = () => interopDefault(import('../pages/transporters-groups/index.vue' /* webpackChunkName: "pages/transporters-groups/index" */))
const _7727315e = () => interopDefault(import('../pages/trips/index.vue' /* webpackChunkName: "pages/trips/index" */))
const _c14f775a = () => interopDefault(import('../pages/trips/index/trip.vue' /* webpackChunkName: "pages/trips/index/trip" */))
const _ee2f9f04 = () => interopDefault(import('../pages/trips/index/trip/_id.vue' /* webpackChunkName: "pages/trips/index/trip/_id" */))
const _f9bc3efe = () => interopDefault(import('../pages/trips/index/trip/_id/index.vue' /* webpackChunkName: "pages/trips/index/trip/_id/index" */))
const _7fe8a2aa = () => interopDefault(import('../pages/trips/index/trip/_id/edit.vue' /* webpackChunkName: "pages/trips/index/trip/_id/edit" */))
const _36f84db1 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _557ccbeb = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _3ec59a50 = () => interopDefault(import('../pages/users/index/create.vue' /* webpackChunkName: "pages/users/index/create" */))
const _2dbfa144 = () => interopDefault(import('../pages/users/index/edit.vue' /* webpackChunkName: "pages/users/index/edit" */))
const _f144c3ee = () => interopDefault(import('../pages/users/index/edit/_id.vue' /* webpackChunkName: "pages/users/index/edit/_id" */))
const _d78397fc = () => interopDefault(import('../pages/users/index/info.vue' /* webpackChunkName: "pages/users/index/info" */))
const _963e9ea6 = () => interopDefault(import('../pages/users/index/info/_id.vue' /* webpackChunkName: "pages/users/index/info/_id" */))
const _bae65358 = () => interopDefault(import('../pages/companies/branches.vue' /* webpackChunkName: "pages/companies/branches" */))
const _925584f6 = () => interopDefault(import('../pages/companies/forwarders.vue' /* webpackChunkName: "pages/companies/forwarders" */))
const _5377fbd8 = () => interopDefault(import('../pages/orders/trips.vue' /* webpackChunkName: "pages/orders/trips" */))
const _2c8550e0 = () => interopDefault(import('../pages/reports/realizations/index.vue' /* webpackChunkName: "pages/reports/realizations/index" */))
const _b9c42e58 = () => interopDefault(import('../pages/public-orders/_id.vue' /* webpackChunkName: "pages/public-orders/_id" */))
const _01a09932 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bi",
    component: _07398a4c,
    name: "bi"
  }, {
    path: "/bidding",
    component: _7d06fb9e,
    name: "bidding",
    children: [{
      path: "create",
      component: _61aaeb3d,
      name: "bidding-index-create"
    }, {
      path: "edit",
      component: _4d0ed78b,
      name: "bidding-index-edit",
      children: [{
        path: ":id?",
        component: _f39b3094,
        name: "bidding-index-edit-id"
      }]
    }, {
      path: "info",
      component: _0fa647a2,
      name: "bidding-index-info",
      children: [{
        path: ":id?",
        component: _98950b4c,
        name: "bidding-index-info-id"
      }]
    }, {
      path: "price-requests/create",
      component: _f9492d1c,
      name: "bidding-index-price-requests-create"
    }, {
      path: "price-requests/edit",
      component: _6c794200,
      name: "bidding-index-price-requests-edit",
      children: [{
        path: ":id?",
        component: _0487a6aa,
        name: "bidding-index-price-requests-edit-id"
      }]
    }, {
      path: "price-requests/info",
      component: _74e163a4,
      name: "bidding-index-price-requests-info",
      children: [{
        path: ":id?",
        component: _2b3f3f4f,
        name: "bidding-index-price-requests-info-id"
      }]
    }]
  }, {
    path: "/companies",
    component: _51b60ffe,
    name: "companies",
    children: [{
      path: "create",
      component: _460e2add,
      name: "companies-index-create"
    }, {
      path: "edit",
      component: _5b0501aa,
      name: "companies-index-edit",
      children: [{
        path: ":id?",
        component: _42778154,
        name: "companies-index-edit-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _3dd39fac,
        name: "companies-index-edit-customerId-clientId"
      }]
    }, {
      path: "info",
      component: _7d9b83cf,
      name: "companies-index-info",
      children: [{
        path: ":id?",
        component: _0c4751fa,
        name: "companies-index-info-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _e0413c64,
        name: "companies-index-info-customerId-clientId"
      }]
    }]
  }, {
    path: "/correspondence",
    component: _2fdd0326,
    name: "correspondence",
    children: [{
      path: "create",
      component: _a1851ce4,
      name: "correspondence-index-create"
    }, {
      path: "edit",
      component: _6f80be1c,
      name: "correspondence-index-edit",
      children: [{
        path: ":id?",
        component: _8d86c472,
        name: "correspondence-index-edit-id"
      }]
    }]
  }, {
    path: "/drivers",
    component: _0ecfa16e,
    name: "drivers",
    children: [{
      path: "create",
      component: _47c31b6d,
      name: "drivers-index-create"
    }, {
      path: "edit",
      component: _ea55588a,
      name: "drivers-index-edit",
      children: [{
        path: ":id?/:companyId?",
        component: _4ef9621c,
        name: "drivers-index-edit-id-companyId"
      }]
    }, {
      path: "info",
      component: _35f3585f,
      name: "drivers-index-info",
      children: [{
        path: ":id?/:companyId?",
        component: _d2e22464,
        name: "drivers-index-info-id-companyId"
      }]
    }]
  }, {
    path: "/instructions",
    component: _654c50b8,
    name: "instructions"
  }, {
    path: "/login",
    component: _549ea80c,
    name: "login"
  }, {
    path: "/no-access",
    component: _76deded3,
    name: "no-access"
  }, {
    path: "/orders",
    component: _865ef010,
    name: "orders",
    children: [{
      path: "create",
      component: _2775a5a3,
      name: "orders-index-create"
    }, {
      path: "edit",
      component: _02f0f371,
      name: "orders-index-edit",
      children: [{
        path: ":id?",
        component: _0438209c,
        name: "orders-index-edit-id"
      }]
    }, {
      path: "info",
      component: _a3e20fd6,
      name: "orders-index-info",
      children: [{
        path: ":id?",
        component: _31bb3340,
        name: "orders-index-info-id",
        children: [{
          path: "getTrip",
          component: _11817ecc,
          name: "orders-index-info-id-getTrip"
        }, {
          path: "trip",
          component: _526695c4,
          name: "orders-index-info-id-trip"
        }, {
          path: "viewTrip",
          component: _fc08b7ee,
          name: "orders-index-info-id-viewTrip"
        }]
      }]
    }]
  }, {
    path: "/password-recovery",
    component: _5fb705aa,
    name: "password-recovery"
  }, {
    path: "/profile",
    component: _3b6e604c,
    name: "profile"
  }, {
    path: "/public-orders",
    component: _f9a00928,
    name: "public-orders"
  }, {
    path: "/references",
    component: _d34ff84a,
    name: "references"
  }, {
    path: "/register",
    component: _61f31036,
    name: "register"
  }, {
    path: "/settings",
    component: _9c328494,
    name: "settings"
  }, {
    path: "/transport",
    component: _61c75bcc,
    name: "transport",
    children: [{
      path: "create",
      component: _7f96c962,
      name: "transport-index-create"
    }, {
      path: "edit",
      component: _ffed37c6,
      name: "transport-index-edit",
      children: [{
        path: ":id?",
        component: _69105b48,
        name: "transport-index-edit-id"
      }]
    }, {
      path: "info",
      component: _2b2768c1,
      name: "transport-index-info",
      children: [{
        path: ":id?",
        component: _d2d92428,
        name: "transport-index-info-id"
      }]
    }]
  }, {
    path: "/transporters",
    component: _52c02aa0,
    name: "transporters"
  }, {
    path: "/transporters-groups",
    component: _5a6a48e7,
    name: "transporters-groups"
  }, {
    path: "/trips",
    component: _7727315e,
    name: "trips",
    children: [{
      path: "trip",
      component: _c14f775a,
      name: "trips-index-trip",
      children: [{
        path: ":id?",
        component: _ee2f9f04,
        children: [{
          path: "",
          component: _f9bc3efe,
          name: "trips-index-trip-id"
        }, {
          path: "edit",
          component: _7fe8a2aa,
          name: "trips-index-trip-id-edit"
        }]
      }]
    }]
  }, {
    path: "/unsubscribe",
    component: _36f84db1,
    name: "unsubscribe"
  }, {
    path: "/users",
    component: _557ccbeb,
    name: "users",
    children: [{
      path: "create",
      component: _3ec59a50,
      name: "users-index-create"
    }, {
      path: "edit",
      component: _2dbfa144,
      name: "users-index-edit",
      children: [{
        path: ":id?",
        component: _f144c3ee,
        name: "users-index-edit-id"
      }]
    }, {
      path: "info",
      component: _d78397fc,
      name: "users-index-info",
      children: [{
        path: ":id?",
        component: _963e9ea6,
        name: "users-index-info-id"
      }]
    }]
  }, {
    path: "/companies/branches",
    component: _bae65358,
    name: "companies-branches"
  }, {
    path: "/companies/forwarders",
    component: _925584f6,
    name: "companies-forwarders"
  }, {
    path: "/orders/trips",
    component: _5377fbd8,
    name: "orders-trips"
  }, {
    path: "/reports/realizations",
    component: _2c8550e0,
    name: "reports-realizations"
  }, {
    path: "/public-orders/:id?",
    component: _b9c42e58,
    name: "public-orders-id"
  }, {
    path: "/",
    component: _01a09932,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
