import moment from 'moment';

export const state = () => ({
  isBiddingTableLoading: false,
  query: {},
  biddingData: {},
  ordersListForAuction: [],
  timeFromLoad: null,
  lastLoadedParams: null,
  statusList: [],
  biddingList: [],
  pagination: {},
  productUnitTypes: [],
});

export const getters = {
  getBiddingTableLoading: state => state.isBiddingTableLoading,
  getQuery: state => state.query,
  getStatusList: state => state.statusList,
  getAuctionOrdersList: state => state.ordersListForAuction,
  getTimeFromLoad: state => state.timeFromLoad,
  getLastLoadedParams: state => state.lastLoadedParams,
  getBiddingList: state => state.biddingList,
  getPaginationData: state => state.pagination,
  getProductUnitTypes: state => state.productUnitTypes,
};

export const mutations = {
  setBiddingTableLoading(state, payload) {
    state.isBiddingTableLoading = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setBiddingData(state, payload) {
    state.biddingData = payload;
    state.biddingList = payload.data;
    state.statusList = Object.values(payload.statusLabels);
    state.pagination = {
      current_page: payload.meta.current_page,
      from: payload.meta.from,
      last_page: payload.meta.last_page,
      path: payload.meta.path,
      per_page: payload.meta.per_page,
      to: payload.meta.to,
      total: payload.meta.total,
    };
    state.productUnitTypes = payload.productUnitTypes;
  },

  setLastLoadingDate(state) {
    state.timeFromLoad = moment();
  },

  setLastLoadingParams(state, payload) {
    state.lastLoadedParams = payload;
  },

  setAuctionOrdersList(state, payload) {
    state.ordersListForAuction = payload;
  },

  clearOrdersListForAuction(state) {
    state.ordersListForAuction = [];
  },
};

export const actions = {
  loadBidding({ commit }, { params, disableLoading }) {
    if (!disableLoading) {
      commit('setBiddingTableLoading', true);
    }

    return this.$axios.get('/auctions', {
      params: {
        ...params,
        clients: params.clients?.length ? JSON.parse(params.clients).map(({ id }) => Number(id)) : undefined,
      },
    })
      .then(({ data }) => {
        commit('setBiddingData', data);
        commit('setLastLoadingParams', params);
        commit('setLastLoadingDate');
        return data;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        commit('setBiddingTableLoading', false);
      });
  },

  saveQuery({ commit }, payload) {
    commit('setQuery', payload);
  },

  saveAuctionOrdersList({ commit }, payload) {
    commit('setAuctionOrdersList', payload);
  },
};
