export const state = () => ({
  analyticsText: '',
});

export const getters = {
  getAnalyticsText: state => state.analyticsText,
};

export const mutations = {
  setAnalyticsText(state, payload) {
    state.analyticsText = payload;
  },
};

export const actions = {

  async loadAnalyticsText({ commit }) {
    await this.$axios.get('/instructions/bi')
      .then(({ data: { data: { bi: text } } }) => {
        commit('setAnalyticsText', text);
      })
      .catch(error => console.log(error));
  },

  async saveAnalyticsText(_, payload) {
    await this.$axios.post('/settings/instruction/save', payload);
  },
};
