import moment from 'moment';
import { getTypes } from '~/assets/js/utils';

export const state = () => ({
  isOrdersFormLoading: false,
  order: {},
  labels: {},
  transporterGroups: [],
  isTransportersGroupsLoading: false,
  clients: [],
  history: [],
  dataForCreation: {},
});

const transformIntoFormData = (formData, products, productUnitTypes, clientsList) => {
  const product = products.find(productItem => productItem.products.some(product2 => product2.id === formData.product_id));

  return {
    ...formData,
    loadingTypes: formData.loading_types,
    unloadingTypes: formData.unloading_types,
    trailerTypes: formData.trailer_types,
    product,
    is_limited_time: Boolean(formData.datetime_limit),
    is_insured: Boolean(formData.insurance),
    insurance: formData.insurance,
    limit_date: formData.datetime_limit
      ? moment(formData.datetime_limit.split(' ')[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
      : null,
    limit_time: formData.datetime_limit
      ? moment(formData.datetime_limit.split(' ')[1], 'HH-mm-ss').format('HH-mm')
      : null,
    product_unit_type: formData.product_unit_type
      && productUnitTypes.find(productUnitType => productUnitType.id === formData.product_unit_type),
    currency_unit_type: formData.product_unit_type && formData.currency_unit_type
      && productUnitTypes
        .find(productUnitType => productUnitType.id === formData.product_unit_type).types
        .find(type => type.id === formData.currency_unit_type),
    name: product && product.products.find(product1 => product1.id === formData.product_id),
    unloading_date: formData.unloading_date ? moment(formData.unloading_date, 'YYYY-MM-DD')
      .subtract(formData.additional_days, 'days').format('YYYY-MM-DD') : '',
    client_id: formData.id && clientsList
      ? clientsList.find(cur => Number(cur.id) === formData.client_id)
      : null,
    customer: formData.customer || {},
  };
};

export const getters = {
  GET_ORDER: state => state.order,
  GET_PRODUCTS: state => state.labels.products || [],
  GET_STATUSES: state => state.labels.statuses || [],
  GET_CONTRACTING_TYPES: state => Object.values(state.labels.contractingTypes) || [],
  GET_REALIZATION_STATUSES: state => Object.values(state.labels.realizationStatuses) || [],
  GET_LOADING_TYPES: state => state.labels.loadingTypes || [],
  GET_UNLOADING_TYPES: state => state.labels.unloadingTypes || [],
  GET_TRAILER_TYPES: state => getTypes(state.labels.carTrailerTypes, 'child_types') || [],
  GET_PRODUCT_UNIT_TYPES: state => state.labels.productUnitTypes.slice()
    .sort((typeA, typeB) => typeA.id - typeB.id) || [],
  // eslint-disable-next-line no-shadow
  GET_FORM_DATA: (state, getters) => transformIntoFormData(
    state.order,
    state.labels.products || [],
    state.labels.productUnitTypes
      .slice()
      .sort((typeA, typeB) => typeA.id - typeB.id) || [],
    getters.GET_CLIENTS_READY
  ),
  GET_CONTAINER_TYPES: state => Object.values(state.labels.containerTypes),
  GET_MODULE_TYPES: state => state.labels.typeTransportationLabels,
  GET_DANGER_CLASSES: state => Object.values(state.labels.hazardClassLabels),
  GET_CLIENTS_READY: state => (state.dataForCreation.customers || state.dataForCreation.clients || [])
    .map(client => ({ ...client, name: client.name || client.inn })),
  GET_VAT_LABELS: state => state.labels.VATLabels,
  GET_TRANSACTION_TITLE_LABELS: state => Object.values(state.labels.transactionTitleLabels),
  GET_TRANSACTION_TYPE_LABELS: state => Object.values(state.labels.transactionTypeLabels),
  GET_MEDIA_DOC_MAIN_LABELS: state => state.labels.mediaDocumentMain,
  GET_DOCUMENT_MAIN_MODEL_NAME: state => state.labels.documentMainModelName,
  GET_TRANSPORTER_GROUPS: state => state.transporterGroups,
  GET_IS_TRANSPORTERS_GROUPS_LOADING: state => state.isTransportersGroupsLoading,
  GET_ORDER_HISTORY: state => state.history,
  GET_PERSONAL_SETTINGS: state => state.dataForCreation.personal_settings || {},
};

export const actions = {
  SAVE_ORDER({ commit, dispatch }, payload) {
    const isUpdate = Boolean(payload.id);
    const url = isUpdate ? `orders/${payload.id}` : 'orders';
    const method = isUpdate ? 'put' : 'post';

    commit('setOrdersFormLoading', true);
    return this.$axios[method](url, {
      ...payload,
    })
      .then(({ data }) => data)
      .finally(() => {
        commit('setOrdersFormLoading', false);
      });
  },

  LOAD_ORDER({ commit, dispatch }, payload) {
    commit('setOrdersFormLoading', true);
    return this.$axios.get(`/orders/${payload}`)
      .then(({ data }) => {
        commit('setOrder', data.data);
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        commit('setOrdersFormLoading', false);
      });
  },

  LOAD_ORDER_MODULE({ commit, dispatch }, payload) {
    commit('setOrdersFormLoading', true);
    return this.$axios.get(`/orders/${payload.id}/${payload.moduleId}`)
      .then(({ data }) => {
        commit('setOrder', data.data);
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        commit('setOrdersFormLoading', false);
      });
  },

  REFUSE_ORDER(_, payload) {
    return this.$axios.get(`/orders/${payload}/refuse`);
  },

  LOAD_LABELS({ commit, rootGetters }) {
    return this.$axios.get('/orders/labels')
      .then(({ data }) => {
        commit('setLabels', data);
      }).catch(e => console.log(e));
  },

  LOAD_TRANSPORTER_GROUPS({ commit }) {
    commit('setIsTransporterGroupsLoading', true);

    return this.$axios.get('/groups')
      .then(({ data }) => {
        commit('setTransporterGroups', data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit('setIsTransporterGroupsLoading', false);
      });
  },

  LOAD_ORDER_HISTORY({ commit }, id) {
    return this.$axios.get(`/orders/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
      })
      .catch(e => console.log(e));
  },

  CLEAR_ORDER_DATA({ commit }) {
    commit('clearOrder');
  },

  LOAD_DATA_FOR_CREATION({ commit }) {
    return this.$axios.get('/orders/data-for-creation')
      .then(({ data }) => {
        commit('setDataForCreation', data);
      });
  },

  DELETE_ORDER(_, payload) {
    return this.$axios.delete(`/orders/${payload.id}`, {
      data: {
        comment: payload.comment,
      },
    });
  },

  LOAD_CUSTOMERS_LIST({ commit }) {
    return this.$axios.get('/orders/customers')
      .then(({ data }) => {
        commit('setClients', data.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  GET_ORDER_TO_COPY({ commit, dispatch }, payload) {
    return Promise.all([
      dispatch('LOAD_ORDER', payload),
      dispatch('LOAD_LABELS'),
    ]);
  },

  LOAD_CONTRACTORS_TARIFFS(_, payload) {
    return this.$axios.get('/orders/get-tariffs', {
      params: payload,
    });
  },
};

export const mutations = {
  setOrdersFormLoading(state, payload) {
    state.isOrdersFormLoading = payload;
  },

  setOrder(state, payload) {
    state.order = {
      ...payload,
      company: {
        inn: payload.client
          ? payload.client.inn : '',
      },
    };
  },

  clearOrder(state) {
    state.order = {};
  },

  setLabels(state, payload) {
    state.labels = {
      ...payload,
    };
  },

  setTransporterGroups(state, payload) {
    state.transporterGroups = payload.groups.data;
  },

  setIsTransporterGroupsLoading(state, payload) {
    state.isTransportersGroupsLoading = payload;
  },

  setDataForCreation(state, payload) {
    state.dataForCreation = payload;
  },

  setHistory(state, payload) {
    state.history = payload;
  },
};
