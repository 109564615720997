const authPages = {
  '/password-recovery': true,
  '/login': true,
  '/register': true,
};

const exeptOuterRoutes = {
  '/password-recovery': true,
  '/login': true,
  '/register': true,
  '/public-orders': true,
  '/unsubscribe': true,
  '/no-access': true,
};

const exeptOuterRoutesName = {
  'public-orders-id': true,
};

const exeptInnerRoutes = {
  '/': true,
};

const currentRoles = {
  2: 'admin',
  3: 'client',
  4: 'transporter',
};

const isEdit = {
  users: [
    'users-index-edit-id',
    'users-index-create',
  ],
};

let previousRoute = '';

function protectPages(route, store) {
  const routeName = route.path.split('/')[1];
  const { currentAccess, currentUser } = store.state.main;
  const isAdmin = currentUser.roles.find(({ id }) => currentRoles[id] === 'admin');
  const isClientOrForwarder = store.getters['main/isClientOrForwarder'];
  const isTransporter = currentUser.roles.find(({ id }) => currentRoles[id] === 'transporter');
  const isUserCompanyRequiringAccreditation = store.getters['main/isUserCompanyRequiringAccreditation'];

  if (routeName === 'trips' && currentAccess.statistics) {
    return true;
  }

  if (routeName === 'unsubscribe') {
    return true;
  }

  if (routeName === 'correspondence' && currentAccess.documents_flow) {
    return true;
  }

  if (routeName === 'public-orders') {
    return true;
  }

  if (routeName === 'orders' && isUserCompanyRequiringAccreditation) {
    return true;
  }

  if (routeName === 'companies') {
    if (isTransporter) {
      return false;
    }

    return isAdmin || currentAccess.transporters || currentAccess.customers || currentAccess.branches;
  }

  // Check router permissions
  // if (routeName === 'cars' || routeName === 'drivers') {
  //   if (!(isAdmin || isTransporter)) {
  //     return false;
  //   }
  // }

  if (route.path === '/reports/realizations' && !isTransporter) {
    return true;
  }

  if (routeName === 'profile' && isAdmin) {
    return false;
  }

  if (routeName === 'bi' && (isAdmin || isClientOrForwarder)) {
    return true;
  }

  // Вкладка "Инструкции" доступна всем для просмотра
  if (routeName === 'instructions') {
    return true;
  }

  if (routeName === 'transporters' && !isTransporter) {
    return true;
  }

  // TODO: отрефакторить проверку
  if (routeName === 'transporters-groups' && isClientOrForwarder) {
    return true;
  }

  let currentAccessIdentifier = '';
  if (routeName === 'transport') {
    currentAccessIdentifier = 'cars';
  } else if (routeName === 'profile') {
    currentAccessIdentifier = 'profile';
  } else if (routeName === 'bidding') {
    currentAccessIdentifier = 'auctions';
  } else {
    currentAccessIdentifier = routeName;
  }

  const localAccess = currentAccess[currentAccessIdentifier];
  if (localAccess) {
    const editPermission = isEdit[routeName];

    if (!editPermission) return true;

    if (localAccess.edit) return true;

    return !editPermission.some(v => v === route.name);
  }

  return false;
}

export default async function({ route, store, redirect }) {
  if (store.state.general.token && authPages[route.path]) {
    redirect('/');
  }

  // store, $axios , query, params, route
  if (exeptOuterRoutes[route.path]
    || route.name === previousRoute
    || exeptOuterRoutesName[route.name]
  ) {
    return;
  }

  previousRoute = route.name;
  await store.dispatch('main/getInfo');

  if (exeptInnerRoutes[route.path]) return;

  if (!protectPages(route, store)) {
    redirect('/no-access');
  }
}
