var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-field",class:{
    't-field--no-label': !_vm.hasLabel,
    't-field--loading': _vm.loading,
    't-field--focused-loading': _vm.canEditLoading && _vm.loading,
  }},[(_vm.hasLabel)?_c('span',{staticClass:"t-field__label",class:{
      't-field__label--error': _vm.hasErrors,
      't-field__label--success': !_vm.hasErrors && _vm.hasSuccessMessages,
    }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"t-field__field-wrapper"},[_c(_vm.multiline ? 'textarea' : 'input',{ref:"inputComponentRef",tag:"Component",staticClass:"t-field__field",class:{
        't-field__field--error': _vm.hasErrors,
        't-field__field--success': !_vm.hasErrors && _vm.hasSuccessMessages,
        't-field__field--multiline': _vm.multiline,
        't-field__field--has-button': _vm.hasButton,
        't-field__field--auto-expanded': _vm.isAutoExpanded,
      },attrs:{"id":_vm.id,"type":_vm.multiline ? undefined : _vm.type,"rows":_vm.multiline ? _vm.rows : undefined,"placeholder":_vm.placeholder || undefined,"required":_vm.required,"name":_vm.name,"maxlength":_vm.maxLength,"disabled":_vm.isDisabled,"autocomplete":_vm.multiline ? undefined : _vm.autocomplete,"readonly":_vm.readOnly},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"input":_vm.handleInput,"change":function($event){return _vm.$emit('change', $event.target.value)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('enter', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.target.value)}}}),_vm._v(" "),(_vm.hasIcon)?_c('TIconSvg',{staticClass:"t-field__icon",attrs:{"name":_vm.iconName,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"fill":_vm.iconFill,"stroke":_vm.iconStroke},on:{"click":function($event){return _vm.$emit('icon-click', $event)}}}):_vm._e(),_vm._v(" "),_vm._t("button")],2),_vm._v(" "),(_vm.hasErrors)?_c('div',{staticClass:"t-field__hint t-field__hint--error j-error"},[_vm._v("\n    "+_vm._s(_vm.errorMessages[0])+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.hasErrors && _vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint t-field__hint--success-message"},[_vm._v("\n    "+_vm._s(Array.isArray(_vm.successMessages) ? _vm.successMessages[0] : _vm.successMessages)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.hint && !_vm.hasErrors && !_vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint"},[_vm._v("\n    "+_vm._s(_vm.hint)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }